import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import Button from '../Button';
import { Card } from '../Card';

const ErrorPage = ({ errors }) => (
  <Card title="Error">
    <div className="error-container">
      <ul className="error-container__errors">
        {errors.map((error, i) => (
          <li key={i}>{error.message}</li>
        ))}
      </ul>
      <Button
        id="error-back-button"
        className="error-container__back-button"
        onClick={() => navigate(-1)}
      >
        Go Back
      </Button>
    </div>
  </Card>
);

ErrorPage.propTypes = {
  errors: PropTypes.array,
};

export default ErrorPage;
