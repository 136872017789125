import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '../Button';
import { Card } from '../Card';
import { Select, TextInput } from '../Input';
import { flattenObject } from '../../helpers';

const UserFilterModal = ({
  id,
  title,
  large,
  medium,
  fullHeight,
  onClose,
  onSubmit,
  data,
  preselectedIds = [],
}) => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkedIds, setCheckedIds] = useState(preselectedIds);
  const [filteredData, setFilteredData] = useState(data);
  const [departmentFilter, setDepartmentFilter] = useState('');
  const [firstNameFilter, setFirstNameFilter] = useState('');
  const [lastNameFilter, setLastNameFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');

  const dataWeWant = [
    'firstName',
    'lastName',
    'email',
    'organisationDetails.departmentName',
  ];

  useEffect(() => {
    filterData();
  }, [departmentFilter, firstNameFilter, lastNameFilter, emailFilter]);

  useEffect(() => {
    if (checkedIds.length === filteredData.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [checkedIds]);

  const handleCheckboxSelect = event => {
    // eslint-disable-next-line no-shadow
    const { id, checked } = event.target;

    if (id === 'selectAll' && checked) {
      setSelectAllChecked(true);
      setCheckedIds(filteredData.map(item => item.id));
      return;
    }

    if (id === 'selectAll' && !checked) {
      const filteredIds = filteredData.map(item => item.id);

      setSelectAllChecked(false);
      setCheckedIds(checkedIds.filter(cid => !filteredIds.includes(cid)));
      return;
    }

    if (checkedIds.includes(id)) {
      setCheckedIds([...checkedIds.filter(i => i !== id)]);
    } else {
      setCheckedIds([...checkedIds, id]);
    }
  };

  const getFilters = () => {
    const flattenedObject = flattenObject(filteredData[0]);
    const keysFromData = Object.keys(flattenedObject);
    const filters = dataWeWant.filter(a => keysFromData.includes(a));

    let departments = [];
    if (filters.includes('organisationDetails.departmentName')) {
      departments = data.map(item => ({
        label: item.organisationDetails?.departmentName,
        value: item.organisationDetails?.departmentName,
      }));
    }

    let uniqueDepartments = [
      ...new Map(departments.map(item => [item.value, item])).values(),
    ];

    uniqueDepartments = uniqueDepartments.filter(
      item => item.label !== undefined
    );

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {filters.includes('firstName') && (
            <TextInput
              type="text"
              label="First name"
              onChange={e => setFirstNameFilter(e.target.value)}
            />
          )}
          {filters.includes('lastName') && (
            <TextInput
              type="text"
              label="Last name"
              onChange={e => setLastNameFilter(e.target.value)}
            />
          )}
        </div>
        {filters.includes('email') && (
          <TextInput
            type="text"
            label="Email"
            onChange={e => setEmailFilter(e.target.value)}
          />
        )}
        {filters.includes('organisationDetails.departmentName') && (
          <div style={{ width: '100%' }}>
            <Select
              label="Department"
              options={uniqueDepartments}
              onChange={e => setDepartmentFilter(e.target.value)}
            />
          </div>
        )}
      </div>
    );
  };

  const filterData = () => {
    let results = data
      .filter(item =>
        item.firstName?.toLowerCase().includes(firstNameFilter.toLowerCase())
      )
      .filter(item =>
        item.lastName?.toLowerCase().includes(lastNameFilter.toLowerCase())
      )
      .filter(item =>
        item.email?.toLowerCase().includes(emailFilter.toLowerCase())
      );

    if (departmentFilter !== '') {
      results = results.filter(
        item => item.organisationDetails?.departmentName === departmentFilter
      );
    }

    const resultIds = results.map(res => res.id);
    const subsetIsContained = (arr, target) =>
      target.every(v => arr.includes(v));

    const subsetFound = subsetIsContained(checkedIds, resultIds);

    if (subsetFound) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }

    if (results.length > 0) setFilteredData(results);
  };

  const displayUsers = () => (
    <Grid container>
      {filteredData.map(item => {
        const flatItem = flattenObject(item);

        return (
          <Grid
            xs={12}
            md={6}
            lg={4}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <Checkbox
              id={flatItem.id}
              size="large"
              checked={checkedIds.includes(flatItem.id)}
              onChange={handleCheckboxSelect}
              sx={{
                color: 'var(--primary-color)',
                '&.Mui-checked': {
                  color: 'var(--primary-color)',
                },
              }}
            />
            <div style={{ flexDirection: 'column' }}>
              <div>
                <p>
                  {flatItem.firstName || null} {flatItem.lastName || null}
                  {' - '}
                  {flatItem['organisationDetails.departmentName'] || null}
                </p>
              </div>
              <p>{flatItem.email || null}</p>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <div className="modal" id="user-filter-modal">
      <div
        className={`modal__box ${large ? 'modal__box--large' : ''} ${
          medium ? 'modal__box--medium' : ''
        }`}
        id={id}
        style={{ width: '66%' }}
      >
        <Card title={title} fullHeight={fullHeight} style={{ width: '75%' }}>
          <div>
            <div>{getFilters()}</div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormControlLabel
              label="Select All"
              control={
                <Checkbox
                  id="selectAll"
                  size="large"
                  checked={selectAllChecked}
                  onChange={handleCheckboxSelect}
                  sx={{
                    color: 'var(--primary-color)',
                    '&.Mui-checked': {
                      color: 'var(--primary-color)',
                    },
                  }}
                />
              }
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ marginRight: '8px' }}>
                <b>
                  Total selected: {checkedIds.length}/{data.length}
                </b>
              </p>
              <Tooltip arrow title="Clear ALL selected">
                <CancelIcon
                  htmlColor="orangered"
                  onClick={() => setCheckedIds([])}
                  sx={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </div>
          </div>
          <div className="modal__box__content">{displayUsers()}</div>
          <div className="modal__box__content__controls">
            <div className="modal__box__content__controls__control">
              <Button
                id="cancel-button"
                colour="grey"
                onClick={() => onClose()}
              >
                Close
              </Button>
            </div>
            <div className="modal__box__content__controls__control">
              <Button
                id="submit-button"
                colour="primary"
                onClick={() => onSubmit(checkedIds)}
              >
                Okay
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

UserFilterModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  fullHeight: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.array.isRequired,
  preselectedIds: PropTypes.array,
};

export default UserFilterModal;
