import React, { useContext } from 'react';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { UserContext } from './UserWrapper';

const ImpersonateBar = () => {
  const { user } = useContext(UserContext);
  // const user = {
  //   'email':'test@test.com',
  //   'organisationDetails': {
  //     'organisationName':'test',
  //   }
  // }
  const jwt = Cookies.get('mitigate-jwt');
  if (jwt && jwt !== 'undefined') {
    const decoded = jwtDecode(jwt);
    if ('impersonating' in decoded) {
      return (
        <div className="impersonate">
          Attention: you are impersonating: {user.email} - {user?.organisationDetails.organisationName}
        </div>
      );
    }
  }
  return <></>;
};

export default ImpersonateBar;
