/* eslint-disable no-param-reassign */
const flattenObject = (obj, prefix = '') => {
  const result = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const propName = prefix ? `${prefix}.${key}` : key;

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(result, flattenObject(obj[key], propName));
      } else {
        result[propName] = obj[key];
      }
    }
  }

  return result;
};

const AwaitElementToShow = id =>
  new Promise(resolve => {
    const getElement = () => {
      const element = document.getElementById(id);
      if (element) {
        resolve(element);
      } else {
        requestAnimationFrame(getElement);
      }
    };
    getElement();
  });

const getCyberRiskScoreAsPercentage = risk => (risk - 25) / (72 - 25); // 72 is the current maximum anyone can score & 25 is the lowest

const hexToHsl = hex => {
  hex = hex.replace(/^#/, '');
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(h => h + h)
      .join('');
  }

  const r = parseInt(hex.slice(0, 2), 16) / 255;
  const g = parseInt(hex.slice(2, 4), 16) / 255;
  const b = parseInt(hex.slice(4, 6), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;

  let h = 0;
  if (delta !== 0) {
    if (max === r) {
      h = ((g - b) / delta) % 6;
    } else if (max === g) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }
    h = Math.round(h * 60);
  }

  const l = (max + min) / 2;
  const s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  return [h, s * 100, l * 100];
};

const hslToHex = (h, s, l) => {
  s /= 100;
  l /= 100;

  const k = n => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = n =>
    l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));

  return `#${Math.round(f(0) * 255)
    .toString(16)
    .padStart(2, '0')}${Math.round(f(8) * 255)
    .toString(16)
    .padStart(2, '0')}${Math.round(f(4) * 255)
    .toString(16)
    .padStart(2, '0')}`;
};

const rotateHexColor = (hex, degree) => {
  const [h, s, l] = hexToHsl(hex);
  const newH = (h + degree) % 360;
  return hslToHex(newH, s, l);
};

export {
  AwaitElementToShow as default,
  flattenObject,
  getCyberRiskScoreAsPercentage,
  rotateHexColor,
};
