import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { EnhancedTable } from '../Table';
import Button from '../Button';
import { Card } from '../Card';

const ListLayout = ({
  title,
  buttonLabel,
  rows,
  columns,
  children,
  showCreate,
  showBack,
  showControls,
  hideCreateButton,
  setShowCreateCallback,
}) => {
  const visibleLabel = buttonLabel || 'Create';

  return (
    <>
      <div className="overview">
        <Card>
          <div className="overview__controls">
            <h1 className="overview__controls__title">{title}</h1>
            {showControls && (
              <div className="btn-group">
                {showBack && (
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </Button>
                )}
                {!hideCreateButton && (
                  <Button
                    colour="primary"
                    onClick={() => setShowCreateCallback(true)}
                  >
                    {visibleLabel}
                  </Button>
                )}
              </div>
            )}
          </div>
          {
            <EnhancedTable
              cols={columns}
              rows={rows}
              sortableColumnIds={columns
                .map(col => {
                  if (col.label !== '') {
                    return col.id;
                  }
                  return undefined;
                })
                .filter(e => e !== undefined)}
            />
          }
        </Card>
      </div>
      {showCreate && children}
    </>
  );
};

ListLayout.defaultProps = {
  showControls: true,
};

ListLayout.propTypes = {
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  rows: PropTypes.array,
  columns: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  showCreate: PropTypes.bool,
  showControls: PropTypes.bool,
  showBack: PropTypes.bool,
  setShowCreateCallback: PropTypes.func,
  hideCreateButton: PropTypes.bool,
};

export default ListLayout;
