import gql from 'graphql-tag';

const GET_LOGGED_IN_USER = gql`
  query GetLoggedInUser {
    loggedInUser {
      id
      email
      firstName
      lastName
      isSuperuser
      belongsToAcademy
      showLeaderboard
      mfaEnabled
      mfaStatus
      industry
      userBranding {
        logo
        primaryColour
        secondaryColour
      }
      useremailpreferences {
        id
        breachReminders
      }
      organisationDetails {
        id
        role
        jobTitle
        lastLogin
        organisationId
        organisationName
        department {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation UpdateUser($id: String!, $updatedProfile: UpdateProfileInput!) {
    updateProfile(id: $id, updatedProfile: $updatedProfile) {
      user {
        id
        email
        firstName
        lastName
        isSuperuser
        organisationDetails {
          id
          role
          jobTitle
          lastLogin
          organisationId
          organisationName
          departmentId
          departmentName
        }
      }
      logoutRequired
      message
    }
  }
`;

const GET_USERS = gql`
  query GetUsers($first: Int  $skip: Int  $adminsOnly: Boolean  $term: String  $sort: String) {
    users(
      first: $first
      skip: $skip
      adminsOnly: $adminsOnly
      term: $term
      sort: $sort
    ) {
      results {
        id
        firstName
        lastName
        email
        mfaStatus
        organisationDetails {
          id
          role
          jobTitle
          lastLogin
          organisationId
          organisationName
          departmentId
          departmentName
        }
      }
      total
      nextOffset
      currentOffset
      prevOffset
    }
    seatCheck {
      seatsAvailable
    }
  }
`;

const GET_USER = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      mfaStatus
      organisationDetails {
        id
        role
        jobTitle
        lastLogin
        roleText
        organisationId
        organisationName
        departmentId
        departmentName
        additionalDepartments {
          id
          department {
            id
            name
          }
        }
      }
    }
  }
`;

const CREATE_OWN_USER = gql`
  mutation CreateOwnUser($newUser: CreateUserInput!) {
    createOwnUser(newUser: $newUser) {
      user {
        id
        firstName
        lastName
        email
        organisationDetails {
          id
          role
          jobTitle
          organisationId
          organisationName
          departmentId
          departmentName
        }
      }
    }
  }
`;

const CREATE_USER_FOR_ORGANISATION = gql`
  mutation CreateUserForOrganisation($newUser: CreateUserInput!) {
    createUserForOrganisation(newUser: $newUser) {
      user {
        id
        firstName
        lastName
        email
        organisationDetails {
          id
          role
          jobTitle
          organisationId
          organisationName
          departmentId
          departmentName
        }
      }
    }
  }
`;

const CREATE_BULK_USER = gql`
  mutation CreateBulkUser($newUsers: CreateBulkUserInput!) {
    createBulkUser(newUsers: $newUsers) {
      user {
        id
        firstName
        lastName
        email
        organisationDetails {
          id
          role
          jobTitle
          organisationId
          organisationName
          departmentId
          departmentName
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($id: String!, $updatedUser: UpdateUserInput!) {
    updateUser(id: $id, updatedUser: $updatedUser) {
      user {
        id
        firstName
        lastName
        email
        organisationDetails {
          id
          jobTitle
          organisationId
          organisationName
          departmentId
          departmentName
        }
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      deleted
    }
  }
`;

const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($id: String!, $resetUser: ResetUserInput!) {
    resetUser(id: $id, resetUser: $resetUser) {
      reset
    }
  }
`;

const REQUEST_SEATS = gql`
  mutation RequestSeats($message: String!) {
    requestSeats(message: $message) {
      requested
    }
  }
`;

const MFA_RESET = gql`
  mutation MfaReset($userId: String!){
    mfaReset(userId: $userId) {
        isReset
    }
  }
`;

export {
  GET_LOGGED_IN_USER,
  UPDATE_PROFILE,
  GET_USERS,
  GET_USER,
  CREATE_OWN_USER,
  CREATE_USER_FOR_ORGANISATION,
  CREATE_BULK_USER,
  UPDATE_USER,
  DELETE_USER,
  RESET_USER_PASSWORD,
  REQUEST_SEATS,
  MFA_RESET
};
