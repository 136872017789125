import React from 'react';
import PropTypes from 'prop-types';
import PlatformColors from '../variables.scss';

const LinearGauge = ({ risk, current, max, fullWidth }) => {
  let background = PlatformColors.lineargreen;

  if (risk >= 0 && risk < 50) {
    background = PlatformColors.red;
  }

  if (risk >= 50 && risk <= 90) {
    background = PlatformColors.linearamber;
  }

  return (
    <div className="linear-gauge">
      <div
        className={`linear-gauge__container ${fullWidth &&
          'linear-gauge__container--full-width'}`}
      >
        <div
          className="linear-gauge__container__bar"
          style={{
            width: `${risk}%`,
            backgroundColor: background,
          }}
        />
        {risk <= 40 ? (
          <span className="linear-gauge__container__textdark">
            {current ? `${current}/${max}` : `${risk}%`}
          </span>
        ) : (
          <span className="linear-gauge__container__text">
            {current ? `${current}/${max}` : `${risk}%`}
          </span>
        )}
      </div>
    </div>
  );
};

LinearGauge.propTypes = {
  risk: PropTypes.number,
  current: PropTypes.number,
  max: PropTypes.number,
  fullWidth: PropTypes.bool,
};

export default LinearGauge;
