import gql from 'graphql-tag';

const GET_ORGANISATIONS = gql`
  query GetOrganisations($first: Int, $skip: Int!, $term: String, $productNames: [String]) {
    organisations(first: $first, skip: $skip, term: $term, productNames: $productNames) {
      results {
        id
        name
        address
        seatAllowance
        isReseller
        hasSso
        seatAllowance
        owner {
          id
          name
        }
        industry {
          id
          name
        }
        departments {
          id
          name
        }
      }
      total
      nextOffset
      currentOffset
      prevOffset
    }
  }
`;

const GET_ORGANISATION = gql`
  query GetOrganisation($organisationId: Int!) {
    organisation(organisationId: $organisationId) {
      id
      name
      address
      seatAllowance
      isReseller
      hasSso
      canWhiteLabel
      latestCompletion {
        createdAt
        completion
      }
      owner {
        id
        name
      }
      branding {
        primaryColour
        secondaryColour
        logoPath
      }
      subscriptions {
        id
        startDate
        endDate
        product {
          id
          name
        }
      }
      members {
        id
        role
        lastLogin
        jobTitle
        department {
          id
          name
        }
        user {
          id
          firstName
          lastName
          email
        }
      }
      departments {
        id
        name
      }
      industry {
        id
        name
      }
    }
  }
`;
const GET_ORGANISATION_USERS = gql`
  query GetOrganisationUsers($organisationId: Int!) {
    organisationUsers(organisationId: $organisationId) {
      id
      firstName
      lastName
      email
      organisationDetails {
        id
        role
      }
    }
  }
`;

const CREATE_ORGANISATION = gql`
  mutation CreateOrganisation($newOrganisation: CreateOrganisationInput!) {
    createOrganisation(newOrganisation: $newOrganisation) {
      organisation {
        id
        name
        address
        seatAllowance
        isReseller
        seatAllowance
        owner {
          id
          name
        }
        subscriptions {
          id
          startDate
          endDate
          product {
            id
            name
          }
        }
        members {
          id
          role
          user {
            id
            firstName
            lastName
            email
          }
        }
        departments {
          id
          name
        }
        industry {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_ORGANISATION = gql`
  mutation UpdateOrganisation($organisationId: Int!  $updatedOrganisation: UpdateOrganisationInput!) {
    updateOrganisation(
      organisationId: $organisationId
      updatedOrganisation: $updatedOrganisation
    ) {
      organisation {
        id
        name
        address
        isReseller
        hasSso
        seatAllowance
        owner {
          id
          name
        }
        subscriptions {
          id
          startDate
          endDate
          product {
            id
            name
          }
        }
        members {
          id
          role
          user {
            id
            firstName
            lastName
            email
          }
        }
        departments {
          id
          name
        }
        industry {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_BRANDING = gql`
  mutation UpdateBranding($primaryColour: String!  $secondaryColour: String!  $file: Upload) {
    updateBranding(
      primaryColour: $primaryColour
      secondaryColour: $secondaryColour
      file: $file
    ) {
      updated
    }
  }
`;

const DELETE_ORGANISATION = gql`
  mutation DeleteOrganisation($organisationId: Int!) {
    deleteOrganisation(organisationId: $organisationId) {
      deleted
    }
  }
`;

const GET_SUBSCRIPTIONS = gql`
  query GetSubscriptions($organisationId: Int!) {
    subscriptions(organisationId: $organisationId) {
      id
      startDate
      endDate
      product {
        id
        name
      }
      organisation {
        id
        name
      }
    }
  }
`;

const GET_SUBSCRIPTION = gql`
  query GetSubscription($subscriptionId: Int!) {
    subscription(subscriptionId: $subscriptionId) {
      id
      startDate
      endDate
      product {
        id
        name
      }
      organisation {
        id
        name
      }
    }
  }
`;

const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($newSubscription: CreateSubscriptionInput!) {
    createSubscription(newSubscription: $newSubscription) {
      subscription {
        id
        startDate
        endDate
        product {
          id
          name
        }
        organisation {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($updatedSubscription: UpdateSubscriptionInput!) {
    updateSubscription(updatedSubscription: $updatedSubscription) {
      subscription {
        id
        startDate
        endDate
        product {
          id
          name
        }
        organisation {
          id
          name
        }
      }
    }
  }
`;

const GET_INDUSTRIES = gql`
  query GetIndustries {
    industries {
      id
      code
      name
    }
  }
`;

const GET_SEATS = gql`
  query GetSeats {
    seatCheck {
      seatsAvailable
    }
  }
`;

const GET_SSO_SETTINGS = gql`
  query GetSSOSettings {
    ssoSettings {
      domain
      tenantId
      clientId
      clientSecret
    }
  }
`;

const UPDATE_SSO_SETTINGS = gql`
  mutation UpdateSSOSettings($ssoSettings: SSOSettingsInput!) {
    updateSsoSettings(ssoSettings: $ssoSettings) {
      updated
    }
  }
`;

const GET_SUB_ONLY_COURSES = gql`
  query GetSubscriptionOnlyCourses {
    subscriptionOnlyCourses {
      id
      name
    }
  }
`;

const GET_CONTENT_SUBSCRIPTIONS = gql`
  query GetContentSubscriptions($organisationId: Int!) {
    contentSubscriptions(organisationId: $organisationId) {
      id
      startDate
      endDate
      course {
        id
        name
      }
      organisation {
        id
        name
      }
    }
  }
`;

const CREATE_CONTENT_SUBSCRIPTION = gql`
  mutation CreateContentSubscription($newContentSubscription: CreateContentSubscriptionInput!) {
    createContentSubscription(newContentSubscription: $newContentSubscription) {
      contentSubscription {
        id
        startDate
        endDate
        course {
          id
          name
        }
        organisation {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_CONTENT_SUBSCRIPTION = gql`
  mutation UpdateContentSubscription($updatedContentSubscription: UpdateContentSubscriptionInput!) {
    updateContentSubscription(
      updatedContentSubscription: $updatedContentSubscription
    ) {
      contentSubscription {
        id
        startDate
        endDate
        course {
          id
          name
        }
        organisation {
          id
          name
        }
      }
    }
  }
`;

const IMPERSONATE_USER = gql`
  mutation ImpersonateUser($id: String!) {
    impersonateUser(userId: $id)
    {
      token
      refreshExpiresIn
    }
 }
`;

const CREATE_PEN_TEST_SCAN_ALLOWANCE = gql`
 mutation CreatePentestScanAllowanceMutation($organisationId: Int! $purchaseDays: String!){
  createPentestAllowance(allowance: {
    organisationId: $organisationId,
    purchasedDays: $purchaseDays,
    scanDaysOverMax: "0"
  }) {
    pentestScanAllowance {
      organisation {
        id
        name
      }
      purchasedDays
      scanDaysOverMax
    }
  }
}
`;

const UPDATE_PEN_TEST_SCAN_ALLOWANCE = gql`
mutation UpdatePentestScanAllowanceMutation($organisationId: Int! $purchasedDays: String!){
  updatePentestAllowance(allowance: {
    organisationId: $organisationId,
    purchasedDays: $purchasedDays
  }) {
    pentestScanAllowance {
      organisation {
        id
        name
      }
      purchasedDays
      scanDaysOverMax
    }
  }
}`

const UPDATE_USED_SCAN_DAYS = gql`
mutation($scanId: String! $usedDays: String!){
  updateScanUsedDays(id: $scanId, usedScanDays: $usedDays) {
    scan {
      id
      usedScanDays
    }
  }
}`

export {
  GET_ORGANISATIONS,
  GET_ORGANISATION,
  GET_ORGANISATION_USERS,
  CREATE_ORGANISATION,
  UPDATE_ORGANISATION,
  UPDATE_BRANDING,
  DELETE_ORGANISATION,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTION,
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  GET_INDUSTRIES,
  GET_SEATS,
  GET_SSO_SETTINGS,
  UPDATE_SSO_SETTINGS,
  GET_SUB_ONLY_COURSES,
  GET_CONTENT_SUBSCRIPTIONS,
  CREATE_CONTENT_SUBSCRIPTION,
  UPDATE_CONTENT_SUBSCRIPTION,
  IMPERSONATE_USER,
  CREATE_PEN_TEST_SCAN_ALLOWANCE,
  UPDATE_PEN_TEST_SCAN_ALLOWANCE,
  UPDATE_USED_SCAN_DAYS
};