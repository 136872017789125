import PlatformColors from '../variables.scss';

const setHoverColour = (id, isCitationUser = false, active, reset = false) => {
  if (active) return;

  const el = document.getElementById(id);
  if (el) {
    if (reset) {
      el.style.borderColor = 'transparent';
    } else {
      el.style.borderColor = isCitationUser
        ? PlatformColors.navhighlight
        : 'var(--primary-color)';
    }
  }
};

export default setHoverColour;
