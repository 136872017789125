import atlasblackLogo from './atlasblack.svg';
import atlaswhiteLogo from './atlaswhite.svg';
import cyberlearnLogo from './cyberlearn.svg';
import cyberphishLogo from './cyberphish.svg';
import cybercomplyLogo from './cybercomply.svg';
import cybercertLogo from './miticert.svg';
import cyberhackLogo from './cyberhack.svg';
import cyberintelLogo from './cyberintel.svg';

// TODO: Martin to supply
import mitiSureLogo from './cybersure.svg';
import cyberConsultLogo from './cyberconsult.svg';
import cyberHealthLogo from './cyberhealth.svg';

const getLogo = logo => {
  switch (logo.toLowerCase()) {
    case 'atlasMainLogo':
    case 'atlasWhite':
      return atlaswhiteLogo;

    case 'atlasblack':
      return atlasblackLogo;

    case 'cyberlearn':
      return cyberlearnLogo;

    case 'cyberphish':
    case 'cyberphish-alt':
      return cyberphishLogo;

    case 'cybercomply':
    case 'cybercomply-alt':
      return cybercomplyLogo;

    case 'cybercert':
    case 'cybercert-alt':
      return cybercertLogo;

    case 'cyberhack':
    case 'cyberhack-alt':
      return cyberhackLogo;

    case 'cyberintel':
    case 'cyberintel-alt':
      return cyberintelLogo;

    case 'mitisure':
      return mitiSureLogo;

    case 'cyberconsult':
      return cyberConsultLogo;

    case 'cyberhealth':
      return cyberHealthLogo;

    default:
      return atlaswhiteLogo;
  }
};

export default getLogo;
