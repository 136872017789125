import React from 'react';

import { ApolloConsumer } from '@apollo/client';
import gql from 'graphql-tag';
import Icon from '../Icon';

//* ==========================================================================
//* COMPONENT: Breadcrumb
//* DOES:      Provides a Citation styled breadcrumb for any page that
//*            includes it. It is driven by the browser URL and thus
//*            needs no properties at this time.
//* NOTES:
//* (a) The product WAS NEVER DESIGNED with a breadcrumb trail in mind which
//*     means that the code in this file is, of necessity, ugly and evil, having
//*     to do what's needed on a per-product basis to make a decent trail appear.
//*     Sorry.
//* (b) The breadcrumb trail is currently READ-ONLY as again, not having been
//*     designed from the outset, we just can't consider interactivity now.
const Breadcrumb = () => (
  <ApolloConsumer>
    {client => {
      const trail = buildTrail(client);
      return (
        <div className="breadcrumb-trail" data-breadcrumb>
          <span className="breadcrumb-trail__root">{trail.root}</span>
          {trail.path.map((label, index) => (
            <span key={`bcnode-${index}`}>
              <Icon className="breadcrumb-trail__sep" name="Breadcrumb" />
              {textMapper(trail.product, label)}
            </span>
          ))}
        </div>
      );
    }}
  </ApolloConsumer>
);

//* ==========================================================================
//* FUNCTION: buildTrail
//* DOES:     A somewhat clumsy but reliable breadcrumb processor; given
//*           the seeming chaotic nature of urls, this attempts to give
//*           back a meaningful trail for wherever we happen to be.
//*           Oh for forward planning.
const buildTrail = client => {
  const trail = { root: 'Atlas', path: [] };
  const path = window.location.pathname;
  // Simple cases first: if the URL is a ground term i.e. contains no
  // variable parts then we can do a quick lookup and substitution.
  const masterMap = {
    '/home': { root: 'User', path: ['Dashboard'] },
    '/profile': { root: 'User', path: ['Profile'] },
    '/departments': { root: 'Organisation', path: ['Departments'] },
    '/users': { root: 'Organisation', path: ['Users'] },
    // LEARN
    '/app/cyberlearn': { root: 'Cyber Learn', path: ['Home'] },
    '/cyberlearn': { root: 'Cyber Learn', path: ['Dashboard'] },
    '/cyberlearn/courses': { root: 'Cyber Learn', path: ['Admin', 'Courses'] },
    '/cyberlearn/certificates/admin': {
      root: 'Cyber Learn',
      path: ['Admin', 'Certificates'],
    },
    '/cyberlearn/enrolled-users': {
      root: 'Cyber Learn',
      path: ['Admin', 'Enrolled Users'],
    },
    '/cyberlearn/edit-users': { root: 'Cyber Learn', path: ['Edit users'] },
    '/cyberlearn/learning-objectives': {
      root: 'Cyber Learn',
      path: ['Learning Objectives'],
    },
    '/cyberlearn/learning-settings': {
      root: 'Cyber Learn',
      path: ['Learning Settings'],
    },
    '/cyberlearn/jobs-list': { root: 'Cyber Learn', path: ['Jobs'] },
    // PHISH
    '/cyberphish': { root: 'Cyber Phish', path: ['Dashboard'] },
    '/cyberphish/groups': { root: 'Cyber Phish', path: ['Groups'] },
    '/cyberphish/campaigns': { root: 'Cyber Phish', path: ['Campaigns'] },
    '/cyberphish/linked-campaigns': {
      root: 'Cyber Phish',
      path: ['Linked Campaigns'],
    },
    '/cyberphish/users': { root: 'Cyber Phish', path: ['User Performance'] },
    // COMPLY
    '/app/cybercomply': { root: 'Cyber Comply', path: ['Home'] },
    '/cybercomply': { root: 'Cyber Comply', path: ['Document Groups'] },
    '/cybercomply/templates': {
      root: 'Cyber Comply',
      path: ['Policy Templates'],
    },
    '/cybercomply/document-approvals': {
      root: 'Cyber Comply',
      path: ['Document Approvals'],
    },
    '/cybercomply/reminders': {
      root: 'Cyber Comply',
      path: ['Document Review'],
    },
    // CERT
    '/cybercert': { root: 'Cyber Cert', path: ['Dashboard'] },
    '/app/cybercert': { root: 'Cyber Cert', path: ['Dashboard'] },
    '/cybercert/certificates': { root: 'Cyber Cert', path: ['Certificates'] },
    '/cybercert/questionnaires': {
      root: 'Cyber Cert',
      path: ['Questionnaires'],
    },
    // HACK
    '/app/cyberhack': { root: 'Cyber Hack', path: ['Dashboard'] },
    '/cyberhack': { root: 'Cyber Hack', path: ['Dashboard'] },
    '/cyberhack/scans': { root: 'Cyber Hack', path: ['Reports List'] },
    '/cyberhack/scan-request': { root: 'Cyber Hack', path: ['Request A Scan'] },
    // INTEL
    '/cyberintel': { root: 'Cyber Intel', path: ['Dashboard'] },
    '/cyberintel/breaches': { root: 'Cyber Intel', path: ['Breach Status'] },
    '/cyberintel/scans': { root: 'Cyber Intel', path: ['Scans'] },
    '/cyberintel/assets/overview': {
      root: 'Cyber Intel',
      path: ['Agent Inventory', 'Overview'],
    },
    '/cyberintel/assets/list': {
      root: 'Cyber Intel',
      path: ['Agent Inventory', 'Assets'],
    },
    '/cyberintel/scans/reports': {
      root: 'Cyber Intel',
      path: ['Agent Inventory', 'Scan Reports'],
    },
    // CONSULT
    '/cyberconsult': { root: 'Cyber Consult', path: [] },
    // Health
    '/cyberhealth': { root: 'Cyber Health', path: [] },
    // CYBER SURVEY
    '/cybersurvey/dashboard': { root: 'Cyber Survey', path: ['Dashboard'] },
  };
  if (path in masterMap) {
    return masterMap[path];
  }

  // OK, the URL contains variable numbers of parts and probably numeric
  // values for Org-IDs, User-ID-s etc. We need to dig deeper...
  const parts = path.split('/').filter(x => x.length);

  if (parts.length > 0) {
    const [part] = parts;
    trail.product = part;
    trail.root = productText(parts[0]);
    switch (parts[0]) {
      case 'cyberlearn':
        if (parts.length === 1) {
          trail.path.push('Home');
        } else if (parts.length === 2) {
          if (parts[1] === 'courses' || parts[1] === 'enrolled-users') {
            trail.path.push('Admin');
            trail.path.push(parts[1]);
          } else {
            trail.path.push(parts[1]);
          }
        } else if (parts.length === 3) {
          switch (parts[1]) {
            case 'course':
              trail.path.push('Course');
              queryCourseName(client, parts[2], trail.path);
              break;

            case 'reports':
              trail.path.push('Reports');
              if (parts[2] === 'courses') {
                trail.path.push('course');
              } else if (parts[2] === 'departments') {
                trail.path.push('Department Progress');
              } else {
                trail.path.push(parts[2]);
              }
              break;

            case 'certificates':
              trail.path.push('User');
              trail.path.push('Organisation');
              break;

            default:
              break;
          }
        }
        break;

      case 'organisations':
        trail.root = 'Organisation';
        trail.path.push('Organisation Details');
        break;

      case 'cyberphish':
        if (parts.length > 1) {
          if (parts[1] === 'campaigns') {
            if (parts.length === 3) {
              trail.path.push('Campaign');
              queryCampaignTitle(client, parts[2], trail.path);
            }
          } else if (parts[1] === 'linked-campaigns') {
            if (parts.length === 3) {
              trail.path.push('Linked Campaign');
              queryLinkedCampaignTitle(client, parts[2], trail.path);
            }
          }
        }
        break;

      case 'cybercert':
        if (parts.length > 1) {
          if (parts[1] === 'questionnaires') {
            if (parts.length === 3) {
              trail.path.push('Questionnaire');
              queryQuestionnaireTitle(client, parts[2], trail.path);
            }
          }
        }
        break;

      case 'cybercomply':
        if (parts.length > 1) {
          if (parts[1] === 'document-groups') {
            if (parts.length === 3) {
              trail.path.push('Document Groups');
              queryDocumentGroupTitle(client, parts[2], trail.path);
            }
          }
        }
        break;

      case 'cyberhack':
        if (parts.length > 1) {
          if (parts[1] === 'scan') {
            if (parts.length === 3) {
              trail.path.push('Report');
              queryScanReportTitle(client, parts[2], trail.path);
            }
          }
        }
        break;

      case 'cyberintel':
        // TODO: The rebrand branch DOES NOT HAVE multiple scan support
        // so for now I will return a generic path, it also doesn't have
        // the new Global QID management page either.
        if (parts.length > 1) {
          if (parts[1] === 'assets') {
            if (parts.length === 4) {
              trail.path.push('Agent Inventory');
              trail.path.push('Asset Analysis');
              // TODO: API would have to explicitly add __typename to the
              // response and and 'id' to cause Apollo to cache it.
              // queryAssetTitle(client, parts[3], trail.path);
            }
          }
        }
        break;

      case 'cyberconsult':
        if (parts.length > 1) {
          // eslint-disable-next-line no-unused-vars
          const [product, ...rest] = parts;
          rest.forEach(p => {
            if (!parseInt(p)) {
              trail.path.push(p);
            }
          });
        }
        break;

      case 'cyberhealth':
        if (parts.length > 1) {
          // eslint-disable-next-line no-unused-vars
          const [product, ...rest] = parts;
          rest.forEach(p => {
            if (!parseInt(p)) {
              trail.path.push(p);
            }
          });
        }
        break;

      case 'cybersurvey':
        if (parts.length > 1) {
          if (parts[1] === 'previoussurvey') {
            trail.path.push('Previous Survey');
          }
          if (parts[1] === 'newsurvey') {
            trail.path.push('New Survey');
          }
        }
        break;

      default:
        break;
    }
  }
  return trail;
};

//* ==========================================================================
//* FUNCTION: productText
//* DOES:     Maps browser url PRODUCT part into printable string.
const productText = label => {
  const productMap = {
    users: 'Users',
    cyberlearn: 'Cyber Learn',
    cyberphish: 'Cyber Phish',
    cybercomply: 'Cyber Comply',
    cybercert: 'Cyber Cert',
    cyberhack: 'Cyber Hack',
    cyberintel: 'Cyber Intel',
    cyberconsult: 'Cyber Consult',
    cyberhealth: 'Cyber Health',
    cybersurvey: 'Cyber Survey',
  };
  return label in productMap ? productMap[label] : label;
};

//* ==========================================================================
//* FUNCTION: textMapper
//* DOES:     Maps browser url parts into printable string. If the string is
//*           NOT in the list, the raw value is returned.
const textMapper = (product, label) => {
  const strings = {
    'jobs-list': 'Jobs',
    'users-confidence': 'User Confidence',
    'users-overview': 'Users Overview',
  };
  // Handle cases where one application product may have the same url
  // part but the navigation text is something different.
  if (product === 'cyberlearn' && label === 'policies') {
    return 'Signed Policies';
  }
  // DEFAULT if no other special case handler stepped up.
  return label in strings ? strings[label] : label;
};

//* ==========================================================================
//* FUNCTION: query[XXX]Title
//* DOES:     Helpers to scour the Apollo Client cache for title strings.
const cachedLabel = (client, typename, id, field, into) => {
  // console.log("QUERY", i, "=>", x);
  try {
    const entry = client.readFragment({
      id: `${typename}:${id}`,
      fragment: gql`fragment X on ${typename} { ${field} }`,
    });
    // console.log("CACHEDLABEL", entry);
    if (entry) {
      if (field in entry) {
        into.push(entry[field]);
      }
    }
  } catch (error) {
    // Cached field MAY not be present, we add nothing to the trail
  }
};

const queryCourseName = (client, id, into) => {
  cachedLabel(client, 'CourseType', id, 'name', into);
};
const queryCampaignTitle = (client, id, into) => {
  cachedLabel(client, 'CampaignType', id, 'name', into);
};
const queryLinkedCampaignTitle = (client, id, into) => {
  cachedLabel(client, 'LinkedCampaignSettingsType', id, 'title', into);
};
const queryDocumentGroupTitle = (client, id, into) => {
  cachedLabel(client, 'DocumentGroupType', id, 'name', into);
};
const queryQuestionnaireTitle = (client, id, into) => {
  cachedLabel(client, 'CESurveyType', id, 'organisationName', into);
};
const queryScanReportTitle = (client, id, into) => {
  cachedLabel(client, 'ScanType', id, 'jobRef', into);
};

export default Breadcrumb;
