import { ApolloClient } from '@apollo/client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import Cookies from 'js-cookie';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get('mitigate-jwt');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

const getClient = () =>
  new ApolloClient({
    link: ApolloLink.from([
      authLink,
      createUploadLink({
        uri: process.env.REACT_APP_GRAPHQL_URL,
      }),
    ]),
    cache: new InMemoryCache(),
  });

export default getClient;
