import additionalServices from './Citation/AdditionalServices.svg';
import agentInventory from './Citation/AgentInventory.svg';
import agentList from './Citation/AgentList.svg';
import agentReports from './Citation/AgentReport.svg';
import antiBribery from './Citation/AntiBribery.svg';
import antiMoneyLaundering from './Citation/AntiMoneyLaundering.svg';
import approvals from './Citation/Approvals.svg';
import basic from './Citation/Basic.svg';
import breachReport from './Citation/BreachReport.svg';
import breadcrumbChevron from './Citation/BreadcrumbChevron.svg';
import campaigns from './Citation/Campaigns.svg';
import certificates from './Citation/Certificates.svg';
import courses from './Citation/Courses.svg';
import cyberCert from './Citation/CyberCert.svg';
import cyberComply from './Citation/CyberComply.svg';
import cyberHack from './Citation/CyberHack.svg';
import cyberIntel from './Citation/CyberIntel.svg';
import cyberLearn from './Citation/CyberLearn.svg';
import cyberPhish from './Citation/CyberPhish.svg';
import dashboard from './Citation/Dashboard.svg';
import departments from './Citation/Departments.svg';
import documents from './Citation/Documents.svg';
import editusers from './Citation/EditUsers.svg';
import enrolledusers from './Citation/EnrolledUsers.svg';
import expiry from './Citation/Expiry.svg';
import genericcourse from './Citation/genericcourse.svg';
import user from './Citation/User.svg';
import helpRequests from './Citation/HelpRequests.svg';
import home from './Citation/Home.svg';
import jobs from './Citation/Jobs.svg';
import knowledgeBase from './Citation/KnowledgeBase.svg';
import learningObjectives from './Citation/LearningObjectives.svg';
import learningSettings from './Citation/LearningSettings.svg';
import linkedCampaigns from './Citation/LinkedCampaigns.svg';
import logOut from './Citation/LogOut.svg';
import organisation from './Citation/Organisation.svg';
import organisations from './Citation/Organisations.svg';
import overdue from './Citation/Overdue.svg';
import overview from './Citation/Overview.svg';
import plus from './Citation/Plus.svg';
import products from './Citation/Products.svg';
import reminders from './Citation/Reminders.svg';
import reports from './Citation/Reports.svg';
import requestScans from './Citation/RequestScans.svg';
import responses from './Citation/Responses.svg';
import questions from './Citation/Questions.svg';
import renewals from './Citation/Renewals.svg';
import returnToHub from './Citation/ReturnToHub.svg';
import scans from './Citation/Scans.svg';
import scanslist from './Citation/ScansList.svg';
import signedPolicies from './Citation/SignedPolicies.svg';
import submittedQuestionnaires from './Citation/SubmittedQuestionnaires.svg';
import superUser from './Citation/Superuser.svg';
import templates from './Citation/Templates.svg';
import users from './Citation/Users.svg';
import userConfidence from './Citation/UserConfidence.svg';
import userOverview from './Citation/UserOverview.svg';
import userPerformance from './Citation/UserPerformance.svg';
import cyberconsult from './Citation/cyberconsult.svg';
import cyberHealth from './Citation/CyberHealth.svg';
import cybersureUmberella from './Citation/cybersureUmberella.svg';

const CitationIcons = {
  additionalservices: additionalServices,
  agentinventory: agentInventory,
  agentlist: agentList,
  agentreports: agentReports,
  antimoneylaundering: antiMoneyLaundering,
  antibribery: antiBribery,
  approvals,
  basic,
  breachreport: breachReport,
  breadcrumb: breadcrumbChevron,
  campaigns,
  certificates,
  courses,
  cybercert: cyberCert,
  cybercomply: cyberComply,
  cyberconsult,
  cyberhealth: cyberHealth,
  cyberhack: cyberHack,
  cyberintel: cyberIntel,
  cyberlearn: cyberLearn,
  cyberphish: cyberPhish,
  dashboard,
  departments,
  documents,
  editusers,
  enrolledusers,
  expiry,
  genericcourse,
  helprequests: helpRequests,
  home,
  jobs,
  knowledgebase: knowledgeBase,
  learningobjectives: learningObjectives,
  learningsettings: learningSettings,
  linkedcampaigns: linkedCampaigns,
  logout: logOut,
  organisation,
  organisations,
  overdue,
  overview,
  plus,
  products,
  reminders,
  reports,
  requestscans: requestScans,
  responses,
  questions,
  renewals,
  returntohub: returnToHub,
  scans,
  scanslist,
  signedpolicies: signedPolicies,
  submittedquestionnaires: submittedQuestionnaires,
  superuser: superUser,
  templates,
  user,
  users,
  userconfidence: userConfidence,
  useroverview: userOverview,
  userperformance: userPerformance,
  cybersureumberella: cybersureUmberella,
};

export default CitationIcons;
