import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import classNames from 'classnames';
import Icon from '../Icon';
import NavBarLink from './NavBarLink/NavBarLink';
import getLogo from '../Logo/logo';
import { UserContext } from '../../UserWrapper';
import setHoverColour from './highlight-helper';

let prevActiveElementId;

const NavBar = ({
  links,
  bottomLinks,
  logout,
  products,
  showHubLink,
  showDashboardLink,
}) => {
  const { user } = useContext(UserContext);
  const [active, setActive] = useState('');

  const setActiveItem = (url, id) => {
    setActive(url);
    if (prevActiveElementId) {
      const el = document.getElementById(prevActiveElementId);
      if (el) el.style.borderColor = 'transparent';
    }
    prevActiveElementId = id;
  };
  const isCitationUser =
    user?.organisationDetails?.organisationId === 1 ||
    (user?.isSuperuser && user?.organisationDetails === null) ||
    user?.userBranding === null;

  return (
    <nav className="side-nav">
      <div className="side-nav__logo-container">
        {/* TODO: Check that this file name will remain CONSTANT for every Amplify deploy */}
        <img
          alt="logo"
          src={sessionStorage.getItem('brand-logo') || getLogo('atlasMainLogo')}
          className="side-nav__logo-container__logo"
        />
      </div>

      {user.mfaEnabled && (
        <ul className="side-nav__items side-nav__items--main">
          {links.map((link, index) => {
            if (link.hasOwnProperty('links')) {
              return (
                <ToggleItem
                  key={`nav-link-${index}`}
                  link={link}
                  active={active}
                  setActive={setActiveItem}
                  isCitationUser={isCitationUser}
                />
              );
            }

            if (link.hasOwnProperty('product')) {
              return (
                <ProductLink
                  key={`nav-link-${index}`}
                  link={link}
                  active={active}
                  setActive={setActiveItem}
                  isCitationUser={isCitationUser}
                />
              );
            }

            return (
              <li key={`nav-link-${index}`}>
                <NavBarLink
                  id={`nav-link-${index}`}
                  to={link.url}
                  className="side-nav__item"
                  onClick={() => setActiveItem(link.url, `nav-link-${index}`)}
                  isCitationUser={isCitationUser}
                >
                  <span className="side-nav__item__icon">
                    <Icon name={link.icon} />
                  </span>
                  <span className="side-nav__item__label">{link.label}</span>
                  {link.badge > 0 ? (
                    <span className="side-nav__item__badge">{link.badge}</span>
                  ) : (
                    ''
                  )}
                </NavBarLink>
              </li>
            );
          })}
          {products?.map((prod, index) => (
            <li
              id={`nav-product-${index}`}
              className="side-nav__item"
              onClick={prod.action}
              onMouseOver={() =>
                setHoverColour(`nav-product-${index}`, isCitationUser, false)
              }
              onMouseLeave={() =>
                setHoverColour(
                  `nav-product-${index}`,
                  isCitationUser,
                  false,
                  true
                )
              }
            >
              <span className="side-nav__item__icon">
                <Icon name={prod.icon} />
              </span>
              <span className="side-nav__item__label">{prod.label}</span>
            </li>
          ))}
        </ul>
      )}

      <ul className="side-nav__items side-nav__items--bottom">
        {bottomLinks.map((link, index) => {
          const id = `bottom-nav-link-${index}`;
          if (link.hasOwnProperty('external')) {
            return (
              <li key={id}>
                <a
                  id={id}
                  className="side-nav__item"
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseOver={() => setHoverColour(id, isCitationUser)}
                  onMouseLeave={() =>
                    setHoverColour(
                      id,
                      isCitationUser,
                      link.url === active,
                      true
                    )
                  }
                >
                  <span className="side-nav__item__icon">
                    <Icon name={link.icon} />
                  </span>
                  <span className="side-nav__item__label">{link.label}</span>
                </a>
              </li>
            );
          }
          return (
            <li key={`nav-bottom-link-${index}`}>
              <NavBarLink
                to={link.url}
                className="side-nav__item"
                id={`nav-bottom-link-${index}`}
                isCitationUser={isCitationUser}
                onClick={() =>
                  setActiveItem(link.url, `nav-bottom-link-${index}`)
                }
              >
                <span className="side-nav__item__icon">
                  <Icon name={link.icon} />
                </span>
                <span className="side-nav__item__label">{link.label}</span>
              </NavBarLink>
            </li>
          );
        })}
        {showHubLink && (
          <li>
            <NavBarLink
              id="nav-bar__hub-link"
              to="/app/cyberlearn"
              className="side-nav__item"
              isCitationUser={isCitationUser}
            >
              <span className="side-nav__item__icon">
                <Icon name="ReturnToHub" />
              </span>
              <span className="side-nav__item__label">Return To Hub</span>
            </NavBarLink>
          </li>
        )}
        {showDashboardLink && (
          <li>
            <NavBarLink to="/home" className="side-nav__item">
              <span className="side-nav__item__icon">
                <Icon name="ReturnToHub" />
              </span>
              <span className="side-nav__item__label">Return Home</span>
            </NavBarLink>
          </li>
        )}
        <li
          className="side-nav__item"
          id="link-logout"
          onClick={() => logout()}
          onMouseOver={() => setHoverColour('link-logout', isCitationUser)}
          onMouseLeave={() =>
            setHoverColour('link-logout', isCitationUser, false, true)
          }
        >
          <span className="side-nav__item__icon">
            <Icon name="LogOut" />
          </span>
          <span className="side-nav__item__label">Logout</span>
        </li>
      </ul>
    </nav>
  );
};

NavBar.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
  bottomLinks: PropTypes.arrayOf(PropTypes.object),
  logout: PropTypes.func,
  products: PropTypes.array,
  showHubLink: PropTypes.bool,
  showDashboardLink: PropTypes.bool,
};

const ToggleItem = ({ link, active, setActive, isCitationUser }) => {
  // let location = useLocation();
  const expanded = false;
  // // Check if auto expanded menu content required
  // if (link.root !== undefined && location.pathname.startsWith(link.root)) {
  //   expanded = true;
  // }

  const [showItems, setShowItems] = useState(expanded);

  const id = `nav-dropdown-${link.label}`;

  return (
    <li>
      <a
        id={id}
        className="side-nav__item"
        // eslint-disable-next-line no-script-url
        href="#"
        onClick={() => setShowItems(!showItems)}
        onMouseOver={() =>
          setHoverColour(id, isCitationUser, link.url === active)
        }
        onMouseLeave={() =>
          setHoverColour(id, isCitationUser, link.url === active, true)
        }
      >
        <span className="side-nav__item__icon">
          <Icon name={link.icon} />
        </span>
        <span className="side-nav__item__label">{link.label}</span>
        <span className="side-nav__item__icon side-nav__item__icon--pull-right">
          {showItems === false ? (
            <Icon name="ChevronRight" />
          ) : (
            <Icon name="ChevronDown" />
          )}
        </span>
      </a>
      {showItems && (
        <ul className="side-nav__items__sub-menu">
          {link.links.map((subLink, index) => (
            <li key={`side-nav-${index}`}>
              <NavBarLink
                id={`side-nav-${index}`}
                to={subLink.url}
                className={classNames('side-nav__items__sub-menu__item', {
                  'side-nav__items__sub-menu__item--active':
                    subLink.url === active,
                })}
                onClick={() => setActive(subLink.url)}
                isCitationUser={isCitationUser}
              >
                <span className="side-nav__items__sub-menu__item__icon">
                  <Icon name={subLink.icon} />
                </span>
                <span className="side-nav__items__sub-menu__item__label">
                  {subLink.label}
                </span>
              </NavBarLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

ToggleItem.propTypes = {
  link: PropTypes.object,
  active: PropTypes.string,
  setActive: PropTypes.func,
  isCitationUser: PropTypes.bool,
};

const ProductLink = ({ link, active, setActive, isCitationUser }) => {
  // TODO: Monday: 3772457239 -- local mapping of product names takes
  // place here so cause the shorter names to be displayed until such
  // time as a proper review can be performed on the backend code.
  const productNames = {
    'Cyberlearn': 'Learn',
    'Cybercomply': 'Comply',
    Cyberhack: 'Hack',
    Cybercert: 'Cert',
    Cyberintel: 'Intel',
    'Cyberintel Scan': 'Intel',
    'Cyberintel scans': 'Intel.Scans',
    'Cyberintel Agents': 'Intel.Agents',
    Cyberphish: 'Phish',
    'Cyber Consult': 'Consult',
    'Cyber Health': 'Health',
  };

  const linkText =
    link.product in productNames ? productNames[link.product] : link.product;

  const iconNames = {
    'Cyberlearn': 'CyberLearn',
    'Cybercomply': 'CyberComply',
    Cyberhack: 'CyberHack',
    Cybercert: 'CyberCert',
    'Cyberintel': 'CyberIntel',
    'Cyberintel Scan': 'CyberIntel',
    'Cyberintel scans': 'CyberIntel',
    'Cyberintel Agents': 'CyberIntel',
    Cyberphish: 'CyberPhish',
    'Cyber Consult': 'cyberconsult',
    'Cyber Health': 'cyberhealth',
  };

  let iconContent =  <Icon name="Home" />;
  if (link.product in iconNames) {
    iconContent = <Icon name={iconNames[link.product]} />;
  }

  // 4501324265: PTF: We NEVER want to show the "scans" or "agents"
  // only products but they are sent by API unconditionally.
  const absoluteName = linkText.toLowerCase();
  if (absoluteName.endsWith('agents') || absoluteName.endsWith('scan')) {
    return '';
  }

  // HTML 'id' cannot contain whitespace, so we must remove any spaces
  // priot to extrapolationg the link ID value!
  const cleanLinkId = link.id.replaceAll(' ', '').toLowerCase();
  const id = `${cleanLinkId}-link`;

  return (
    <li className="side-nav__product">
      <Link
        id={id}
        to={link.url}
        className={classNames('side-nav__item', {
          'side-nav__item--active': link.url === active,
        })}
        onMouseOver={() =>
          setHoverColour(id, isCitationUser, link.url === active)
        }
        onMouseLeave={() =>
          setHoverColour(id, isCitationUser, link.url === active, true)
        }
        onClick={() => setActive(link.url, id)}
      >
        <span className="side-nav__item__icon">
          {iconContent}
        </span>
        <span className="side-nav__item__label">
          <b>Cyber</b> {linkText}
        </span>
      </Link>
    </li>
  );
};

ProductLink.propTypes = {
  link: PropTypes.object,
  active: PropTypes.string,
  setActive: PropTypes.func,
  isCitationUser: PropTypes.bool,
};

export default NavBar;
