import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Pips = ({ number, colour, xcemMode }) => {
  const pips = [];

  const basePip = xcemMode ? 'xcem_pip' : 'pip';

  for (let i = 0; i < number; i++) {
    pips.push(
      <div
        className={classNames(basePip, { [`${basePip}--${colour}`]: colour })}
        key={`pip-${i}`}
      />
    );
  }

  return <div className="flex">{pips}</div>;
};

Pips.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colour: PropTypes.string,
  xcemMode: PropTypes.bool,
};

export default Pips;
