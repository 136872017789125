import React, { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import { navigate } from '@reach/router';
import { AppSpinner } from './global-components';
import { GET_LOGGED_IN_USER } from './hub-app/pages/users/queries';

const REFRESH_TOKEN = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      token
      payload
      refreshExpiresIn
    }
  }
`;

const UserContext = createContext({
  user: {},
  logout: undefined,
  userRole: undefined,
});

const UserWrapper = ({ logout, children }) => {
  const { loading, error, data } = useQuery(GET_LOGGED_IN_USER, {
    onCompleted: res => {
      if (!res.loggedInUser.mfaEnabled) {
        navigate('/profile');
      }
    },
  });

  const [refreshTokenAuth] = useMutation(REFRESH_TOKEN, {
    onCompleted: res => {
      Cookies.set('mitigate-jwt', res.refreshToken.token, { secure: true });
      Cookies.set('mitigate-expires', res.refreshToken.payload.exp, {
        secure: true,
      });
    },
    onError: () => {
      logout();
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const token = Cookies.get('mitigate-jwt');
      const expiry = Cookies.get('mitigate-expires');
      const now = Math.floor(Date.now() / 1000);
      const timeDifference = expiry - now;

      if (timeDifference <= 90 && token !== undefined) {
        refreshTokenAuth({
          variables: {
            token,
          },
        });
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <AppSpinner />;
  }

  if (error) {
    if (logout){
      logout();
    }
    return null;
  }

  const resolveRole = user => {
    if (user.isSuperuser) {
      return 'SUPER_USER';
    }
    if (user.organisationDetails.role === 1) {
      return 'ORGANISATION_ADMIN';
    }
    if (user.organisationDetails.role === 2) {
      return 'DEPARTMENT_ADMIN';
    }
    if (user.organisationDetails.role === 4) {
      return 'RESELLER_ADMIN';
    }
    return 'USER';
  };

  if (data === undefined) {
    logout();
    return null;
  }
  if (data.loggedInUser.userBranding) {
    const branding = data.loggedInUser.userBranding;
    const root = document.documentElement;

    if (branding.primaryColour) {
      root.style.setProperty('--primary-color', branding.primaryColour);
      root.style.setProperty('--primary-svg-color', branding.primaryColour);
    }

    if (branding.secondaryColour) {
      root.style.setProperty('--secondary-color', branding.secondaryColour);
    }
    if (branding.logo) {
      const logoExists = sessionStorage.getItem('brand-logo');

      // save the logo to session storage is one doesn't already exist
      if (!logoExists) {
        sessionStorage.setItem('brand-logo', branding.logo);
      }
    }
  }

  return (
    <UserContext.Provider
      value={{
        user: { ...data.loggedInUser, role: resolveRole(data.loggedInUser) },
        logout,
        role: resolveRole(data.loggedInUser),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserWrapper.propTypes = {
  logout: PropTypes.func,
  children: PropTypes.object,
};

export { UserWrapper, UserContext };
