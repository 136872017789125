import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ToggleList = ({ items }) => {
  const [showAll, toggleShowAll] = useState(false);

  let sliceAmount = items.length > 3 ? 3 : items.length;

  if (showAll) {
    sliceAmount = items.length;
  }

  return (
    <ul className="toggle-list">
      {items.slice(0, sliceAmount).map(item => (
        <li key={item}>{item}</li>
      ))}
      {items.length > 3 && (
        <li
          className="toggle-list__toggler"
          onClick={() => toggleShowAll(!showAll)}
        >
          {!showAll ? `View ${items.length - sliceAmount} More` : 'View Less'}
        </li>
      )}
    </ul>
  );
};

ToggleList.propTypes = {
  items: PropTypes.array,
};

export default ToggleList;
