import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../Card';
import Button from '../Button';

const DeleteModal = ({ title = 'Delete', onClick, onClose }) => (
  <div className="modal">
    <div className="modal__box">
      <Card title={title}>
        <div className="modal__box__content">
          <p className="modal__box__content__text">
            Are you sure, this operation is irreversible?
          </p>
          <div className="modal__box__content__controls">
            <div className="modal__box__content__controls__control">
              <Button colour="grey" onClick={() => onClose(false)}>
                Cancel
              </Button>
            </div>
            <div className="modal__box__content__controls__control">
              <Button
                colour="red"
                id="confirm-delete-button"
                onClick={() => onClick()}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
);

DeleteModal.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

export default DeleteModal;
