import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  CartesianGrid,
  Brush,
} from 'recharts';
import { rotateHexColor } from '../../helpers';
import PlatformColours from '../variables.scss';

const LineChartWrapper = ({
  data,
  showLegend,
  showGrid,
  showZoomTool,
  minWidth,
  minHeight,
}) => {
  const keys = Object.keys(data[0]);
  const xAxis = keys[0];
  const dataPoints = keys.splice(1); // ignore the 1st as that's the x-axis

  return (
    <ResponsiveContainer width="99%" minWidth={minWidth} minHeight={minHeight}>
      <LineChart data={data}>
        <XAxis dataKey={xAxis} />
        <YAxis />
        {showGrid && <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />}
        <Tooltip />
        {showZoomTool && <Brush dataKey={xAxis} height={30} />}
        {showLegend && <Legend />}
        {dataPoints.map((point, i) => (
          <Line
            type="monotone"
            dataKey={point}
            stroke={rotateHexColor(PlatformColours.brandorange, i * 80)}
            strokeWidth={2}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

LineChartWrapper.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf({
      'your X axis data': 'make sure this is first in the object',
      'the rest of your data points': 'some value',
      'more data points': 'some value',
    })
  ),
  showLegend: PropTypes.bool,
  showGrid: PropTypes.bool,
  showZoomTool: PropTypes.bool,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
};

LineChartWrapper.defaultProps = {
  data: [{ 'X Axis': 0, '': 0 }],
  showLegend: true,
  showGrid: true,
  showZoomTool: false,
  minWidth: 200,
  minHeight: 200,
};

export default LineChartWrapper;
