import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';

const CheckBoxGroup = ({ children }) => (
  <div className="form-input__checkbox-group">{children}</div>
);

CheckBoxGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const CheckBox = ({ label, name, id, disabled, checked, onChange }) => (
  <div className="form-input__checkbox">
    <input
      type="checkbox"
      id={id}
      name={name}
      disabled={disabled}
      checked={checked}
      onChange={e => onChange(e)}
    />
    <label htmlFor={id}>{label}</label>
  </div>
);

CheckBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

const TextInput = ({
  type,
  label,
  name,
  defaultValue,
  value,
  id,
  forwardedRef,
  disabled,
  onChange,
  onBlur,
  error,
  large,
  step,
}) => (
  <div
    className={classNames('form-input__text-input', {
      'form-input__text-input--error': error,
      'form-input__text-input--large': large,
    })}
  >
    {type !== 'textarea' ? (
      <input
        type={type}
        id={id}
        name={name}
        step={step}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        onChange={e => onChange(e)}
        onBlur={onBlur ? e => onBlur(e) : null}
        autoComplete="off"
        ref={forwardedRef}
      />
    ) : (
      <textarea
        id={id}
        name={name}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={e => onChange(e)}
        onBlur={onBlur ? e => onBlur(e) : null}
        autoComplete="off"
        ref={forwardedRef}
      />
    )}
    <div
      className={classNames('form-input__text-input__ripple', {
        'form-input__text-input__ripple--error': error,
      })}
    />
    <label>{label}</label>
  </div>
);

TextInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  forwardedRef: PropTypes.func,
  error: PropTypes.object,
  large: PropTypes.bool,
  step: PropTypes.string,
};

const Select = ({
  label,
  name,
  defaultValue,
  value,
  id,
  disabled,
  onChange,
  options,
  forwardedRef,
  error,
  noSort,
  noMargin,
  noSelectPrompt,
}) => (
  <div
    className={classNames('form-input__select', {
      'form-input__select--error': error,
      'form-input__select--no-margin': noMargin,
    })}
  >
    <select
      id={id}
      name={name}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      onChange={e => onChange(e)}
      ref={forwardedRef}
    >
      {(noSelectPrompt === undefined || noSelectPrompt === false) && (
        <option value="">Please Select...</option>
      )}
      {noSort
        ? options?.map((option, index) => (
            <option key={`option-${index}`} value={option.value}>
              {option.label}
            </option>
          ))
        : options
            ?.sort((a, b) => a.label.localeCompare(b.label))
            .map((option, index) => (
              <option key={`option-${index}`} value={option.value}>
                {option.label}
              </option>
            ))}
    </select>
    <span
      className={classNames('form-input__select-arrow', {
        'form-input__select-arrow--error': error,
      })}
    />
    <div
      className={classNames('form-input__select-ripple', {
        'form-input__select-ripple--error': error,
      })}
    />
    <label>{label}</label>
  </div>
);

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  forwardedRef: PropTypes.func,
  error: PropTypes.object,
  noSort: PropTypes.bool,
  noMargin: PropTypes.bool,
  noSelectPrompt: PropTypes.bool,
};

const DateInput = ({ value, onChange, label, inline }) => (
  <div className="form-input__date-input">
    <DatePicker
      selected={value}
      onChange={date => onChange(date)}
      dateFormat="dd/MM/yyyy"
      inline={inline}
    />
    <div className="form-input__date-input__ripple" />
    <label>{label}</label>
  </div>
);

DateInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  inline: PropTypes.bool,
};

export { CheckBox, CheckBoxGroup, TextInput, Select, DateInput };
