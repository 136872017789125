import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from 'recharts';

import PlatformColors from '../variables.scss';

const colour = {
  info: PlatformColors.stackedinfo,
  low: PlatformColors.stackedlow,
  medium: PlatformColors.stackedmedium,
  high: PlatformColors.stackedhigh,
};

const StackedBarChartWrapper = ({ data }) => (
  <ResponsiveContainer
    width="100%"
    minWidth={200}
    minHeight={200}
    maxHeight={200}
  >
    <BarChart
      width="100%"
      height="100%"
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Bar dataKey="information" stackId="a" fill={colour.info} />
      <Bar dataKey="low risk" stackId="a" fill={colour.low} />
      <Bar dataKey="medium risk" stackId="a" fill={colour.medium} />
      <Bar dataKey="high risk" stackId="a" fill={colour.high} />
    </BarChart>
  </ResponsiveContainer>
);

StackedBarChartWrapper.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default StackedBarChartWrapper;
