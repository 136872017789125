exports = module.exports = require("../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "", ""]);

// Exports
exports.locals = {
	"primary": "#292a2b",
	"offblack": "#75757b",
	"textblack": "#292a2b",
	"pink": "#aa2670",
	"red": "#d3273e",
	"darkred": "#b7112b",
	"purple": "#6a1ecb",
	"brightred": "#f53a47",
	"lightred": "#e3717d",
	"orange": "#f28e00",
	"amber": "#f28e00",
	"yellow": "#f8c753",
	"green": "#14a38b",
	"cyan": "#52c6f4",
	"blue": "#0097e3",
	"beige": "#ececf0",
	"grey": "#e0e0e7",
	"brandorange": "#e6007e",
	"areastroke": "#f28e00",
	"areagradient": "#f28e00",
	"barcritical": "#6a1ecb",
	"barhigh": "#d3273e",
	"barmedium": "#f28e00",
	"barlow": "#14a38b",
	"barinfo": "#0097e3",
	"piecritical": "#d3273e",
	"piehigh": "#e95c28",
	"piemedium": "#f28e00",
	"pielow": "#f3b14d",
	"pieinfo": "#0097e3",
	"stackedinfo": "#0097e3",
	"stackedlow": "#f6ca70",
	"stackedmedium": "#ec7e00",
	"stackedhigh": "#d3273e",
	"linearred": "#d3273e",
	"linearamber": "#f28e00",
	"lineargreen": "#14a38b",
	"progressinitialpath": "#e0e0e7",
	"progressinitialtext": "#292a2b",
	"progressinitialtrail": "#ececf0",
	"progressinitialbg": "#f6f6f6",
	"progressprogresspath": "#f28e00",
	"progressprogresstext": "#292a2b",
	"progressprogresstrail": "#facc80",
	"progressprogressbg": "#facc80",
	"progresscompletedpath": "#14a38b",
	"progresscompletedtext": "#292a2b",
	"progresscompletedtrail": "#afded5",
	"progresscompletedbg": "#dff2ef",
	"progressfiftypcpath": "#e3717d",
	"progressfiftypctext": "#292a2b",
	"progressfiftypctrail": "#ed99a1",
	"progressfiftypcbg": "#feebf0",
	"progressproviderfill": "#104556",
	"riskdialred": "#d3273e",
	"riskdialamber": "#f28e00",
	"riskdialgreen": "#14a38b",
	"riskdiallightred": "#e3717d",
	"riskdialneedlecolor": "#104556",
	"starrated": "#f8c753",
	"starhover": "#f8c753",
	"starempty": "#e0e0e7",
	"xcemcritical": "#b7112b",
	"xcemhigh": "#d3273e",
	"xcemmedium": "#f39200",
	"xcemlow": "#14a38b",
	"xceminfo": "#0097e3",
	"navhighlight": "#e6007e"
};