import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

import PlatformColors from '../../variables.scss';
import setHoverColour from '../highlight-helper';

const NavBarLink = props => {
  const [isActive, setIsActive] = useState(false);
  return (
    <Link
      onMouseOver={() =>
        setHoverColour(`${props.id}`, props.isCitationUser, isActive)
      }
      onMouseLeave={() =>
        setHoverColour(`${props.id}`, props.isCitationUser, isActive, true)
      }
      {...props}
      getProps={({ isCurrent }) => {
        setIsActive(isCurrent);
        return {
          style: {
            borderLeft: isCurrent
              ? `5px solid ${
                  props?.isCitationUser
                    ? PlatformColors.navhighlight
                    : 'var(--primary-color)'
                }`
              : '',
          },
        };
      }}
    />
  );
};

NavBarLink.propTypes = {
  id: PropTypes.string.isRequired,
  isCitationUser: PropTypes.bool,
};

NavBarLink.defaultProps = {
  isCitationUser: false,
};

export default NavBarLink;
