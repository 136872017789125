import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Card = ({
  title,
  subTitle,
  withMargin,
  fullHeight,
  centered,
  hover,
  children,
  colour,
  style,
  className,
}) => (
  <div
    className={classNames(
      'card',
      { 'card--with-margin': withMargin },
      { 'card--full-height': fullHeight },
      { 'card--centered': centered },
      { 'card--hover': hover },
      { [`card--${colour}`]: colour },
      className
    )}
    style={style}
  >
    {(title || subTitle) && (
      <div className="card__header">
        {title && <h2 className="card__header__title">{title}</h2>}
        {subTitle && <h3 className="card__header__sub-title">{subTitle}</h3>}
      </div>
    )}
    {children}
  </div>
);

Card.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  withMargin: PropTypes.bool,
  fullHeight: PropTypes.bool,
  centered: PropTypes.bool,
  hover: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  colour: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Card;
