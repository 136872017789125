import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FlipCard = ({
  title,
  subTitle,
  withMargin,
  fullHeight,
  centered,
  front,
  back,
}) => (
  <div
    className={classNames(
      'card card--flip',
      { 'card--with-margin': withMargin },
      { 'card--full-height': fullHeight },
      { 'card--centered': centered }
    )}
  >
    {(title || subTitle) && (
      <div className="card__header">
        {title && <h2 className="card__header__title">{title}</h2>}
        {subTitle && <h3 className="card__header__sub-title">{subTitle}</h3>}
      </div>
    )}
    <div className="card__inner">
      <div className="card__inner__front">{front}</div>
      <div className="card__inner__back">{back}</div>
    </div>
  </div>
);

FlipCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  withMargin: PropTypes.bool,
  fullHeight: PropTypes.bool,
  centered: PropTypes.bool,
  front: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  back: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
};

export default FlipCard;
