import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import { Card } from '../Card';

const Modal = ({
  id,
  title,
  large,
  medium,
  fullHeight,
  onClose,
  onSubmit,
  children,
}) => (
  <div className="modal" id={id}>
    <div
      className={`modal__box ${large ? 'modal__box--large' : ''} ${
        medium ? 'modal__box--medium' : ''
      }`}
    >
      <Card title={title} fullHeight={fullHeight}>
        <>
          <div
            className={`modal__box__content ${
              fullHeight ? 'modal__box__content--full-height' : ''
            }`}
          >
            {children}
          </div>
          <div className="modal__box__content__controls">
            <div className="modal__box__content__controls__control">
              <Button
                id="cancel-button"
                colour="grey"
                onClick={() => onClose()}
              >
                Close
              </Button>
            </div>
            {onSubmit && (
              <div className="modal__box__content__controls__control">
                <Button
                  id="submit-button"
                  colour="primary"
                  onClick={() => onSubmit()}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        </>
      </Card>
    </div>
  </div>
);

Modal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  fullHeight: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
};

export default Modal;
