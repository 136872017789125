import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

import { TextInput, Button, useStateReducer } from '../../../global-components';

const USER_AUTH = gql`
  mutation UserAuth($username: String!, $password: String!, $code: String) {
    userAuth(username: $username, password: $password, code: $code) {
      token
      payload
      refreshExpiresIn
    }
  }
`;

const UsernamePasswordForm = ({
  onLogin,
  onPasswordResetRequest,
  returnToLogin,
  email,
  codeRequired,
  primaryColour,
}) => {
  const { state, updateState } = useStateReducer({
    username: email,
    password: '',
    code: '',
    failCount: 0,
  });

  const [userAuth] = useMutation(USER_AUTH, {
    onError: e => {
      e.graphQLErrors.forEach(error => {
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT });
      });
      updateState('failCount')(state.failCount + 1);
    },
    onCompleted: data => {
      onLogin(data.userAuth.token, data.userAuth.payload.exp);
    },
  });

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = () => {
    if (state.loginCaptchaRequired) {
      if (state.loginRecaptchaPassed) {
        userAuth({
          variables: {
            username: state.username.toLowerCase(),
            password: state.password,
            code: state.code,
          },
        });
      } else {
        toast.error('Please complete Captcha', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      userAuth({
        variables: {
          username: state.username.toLowerCase(),
          password: state.password,
          code: state.code,
        },
      });
    }
  };

  return (
    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="login-form__field flex flex--centered">
        <h4>{state.username}</h4>
      </div>

      <div className="login-form__field">
        <TextInput
          type="password"
          name="password"
          id="password-input"
          label="Password"
          value={state.password}
          onChange={e => updateState('password')(e.target.value)}
          forwardedRef={register({ required: true })}
          error={errors.password}
        />
      </div>
      {codeRequired && (
        <div className="login-form__field">
          <TextInput
            type="text"
            name="code"
            id="code-input"
            label="Code"
            value={state.code}
            onChange={e => updateState('code')(e.target.value)}
            forwardedRef={register({ required: true })}
            error={errors.code}
          />
        </div>
      )}

      {state.failCount > 0 && state.failCount < 3 && (
        <div className="login-form__field">
          <p>
            Please note your account will be locked out if you enter your
            details incorrectly 5 times. For help logging in please refer to our{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://citationgroup.freshdesk.com/support/solutions/folders/103000619789"
            >
              knowledge base
            </a>
          </p>
        </div>
      )}

      {state.failCount >= 3 && (
        <ReCAPTCHA
          id="recaptcha-container"
          sitekey="6LeX6wwUAAAAALzJYbkD9rpVbnPWfnOJeiflt33l"
          onExpired={() => updateState('challenge')(false)}
          onChange={() => {
            updateState('challenge')(true);
          }}
        />
      )}

      <div className="login-form__field">
        <Button
          id="btn-login"
          style={{ backgroundColor: primaryColour, color: 'white' }}
          block
        >
          Login
        </Button>
      </div>
      <div className="login-form__field">
        <Button
          block
          colour="black"
          onClick={e => {
            e.preventDefault();
            onPasswordResetRequest(state.username);
          }}
        >
          Reset password
        </Button>
      </div>
      <div className="login-form__field">
        <Button
          block
          small
          id="back-login"
          onClick={e => {
            e.preventDefault();
            returnToLogin();
          }}
        >
          Back
        </Button>
      </div>
    </form>
  );
};

UsernamePasswordForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onPasswordResetRequest: PropTypes.func.isRequired,
  returnToLogin: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  codeRequired: PropTypes.bool,
  primaryColour: PropTypes.string,
};

export default UsernamePasswordForm;
