import PlatformColors from '../variables.scss';

const colours = [
  PlatformColors.orange,
  PlatformColors.blue,
  PlatformColors.offblack,
  PlatformColors.black,
];

const getDefaultTableAxis = data => {
  if (data.length === 0) {
    return {
      defaultLabel: [],
      defaultKeys: [],
    };
  }

  const keys = Object.keys(data[0]);
  const NonIdIndex = keys.findIndex(element => element !== 'id');
  const defaultLabel = keys[NonIdIndex];
  const defaultKeys = keys.filter(
    item => item !== defaultLabel && item !== 'id'
  );

  return {
    defaultLabel,
    defaultKeys,
  };
};

export { colours, getDefaultTableAxis };
