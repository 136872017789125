import React from 'react';
import PropTypes from 'prop-types';
import {
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ResponsiveContainer,
} from 'recharts';

import { colours, getDefaultTableAxis } from './helpers';
import PlatformColors from '../variables.scss';

const AreaChartWrapper = ({ data, dataLabel, dataKeys }) => {
  const { defaultLabel, defaultKeys } = getDefaultTableAxis(data);
  const keys = dataKeys || defaultKeys;

  // 3689660314-REBRAND:
  // TODO: How NOT TO USE palette colours for the tooltip info!
  // LINK: https://codesandbox.io/s/iz33j?file=/src/CustomTooltip.jsx
  // NOTE: This is a lot of work just to change tooltip colours so I
  //     : have decide to post-pone this for now.
  return (
    <ResponsiveContainer
      width="99%"
      maxHeight={200}
      minWidth={200}
      minHeight={200}
    >
      <AreaChart data={data}>
        <XAxis dataKey={dataLabel || defaultLabel} />
        <YAxis domain={[0, 100]} />
        <Tooltip />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={PlatformColors.areastroke}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={PlatformColors.areagradient}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        {keys.map(
          (key, index) =>
            !Number.isNaN(data[0][key]) && (
              <Area
                type="monotone"
                key={`bar-${index}`}
                dataKey={key}
                stroke={colours[index]}
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorUv)"
              />
            )
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

AreaChartWrapper.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataLabel: PropTypes.string,
  dataKeys: PropTypes.arrayOf(PropTypes.string),
};

export default AreaChartWrapper;
