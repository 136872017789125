import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
  ResponsiveContainer,
  Text,
} from 'recharts';

import { getDefaultTableAxis } from './helpers';
import PlatformColors from '../variables.scss';

const BarChartWrapper = ({
  data,
  dataLabel,
  dataKeys,
  noFill,
  riskColors,
  customColours,
}) => {
  const { defaultLabel, defaultKeys } = getDefaultTableAxis(data);
  const keys = dataKeys || defaultKeys;
  const getFill = value => {
    if (value >= 0 && value < 50) {
      return PlatformColors.red;
    }
    if (value >= 50 && value < 90) {
      return PlatformColors.amber;
    }
    return PlatformColors.green;
  };

  const materialColours = [
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#673AB7',
    '#3F51B5',
    '#2196F3',
    '#03A9F4',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
  ];

  if (keys.length === 0) {
    return <Text>No data available</Text>;
  }

  const riskScoreColours = [
    PlatformColors.barcritical,
    PlatformColors.barhigh,
    PlatformColors.barmedium,
    PlatformColors.barlow,
    PlatformColors.barinfo,
  ];

  let actualColors = materialColours;
  if (riskColors) {
    actualColors = riskScoreColours;
  } else if (customColours) {
    actualColors = customColours;
  }

  return (
    <ResponsiveContainer width="100%" minWidth={200} minHeight={200}>
      <BarChart data={data} width="100%" height="100%" maxBarSize={20}>
        <XAxis dataKey={dataLabel || defaultLabel} />
        <YAxis />
        <Tooltip />
        {keys.map(
          (key, index) =>
            !Number.isNaN(data[index][key]) && (
              <Bar key={`bar-${index}`} dataKey={key}>
                {data.map((item, i) =>
                  noFill ? (
                    <Cell key={`cell-${i}`} fill={`${actualColors[i]}`} />
                  ) : (
                    <Cell key={`cell-${i}`} fill={getFill(item[key])} />
                  )
                )}
              </Bar>
            )
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

BarChartWrapper.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataLabel: PropTypes.string,
  dataKeys: PropTypes.arrayOf(PropTypes.string),
  noFill: PropTypes.bool,
  riskColors: PropTypes.bool,
  customColours: PropTypes.arrayOf(PropTypes.string),
};

export default BarChartWrapper;
