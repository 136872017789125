import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Label = ({ state, children }) => (
  <label className={classNames('label', { [`label--${state}`]: state })}>
    {children}
  </label>
);

Label.propTypes = {
  state: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Label;
