import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../global-components';

const SsoForm = ({ email, returnToLogin }) => (
  <div>
    <div className="login-form__field">
      <p id="sso-instructions">
        Your organisation has enabled single sign-on, meaning you do not need to
        enter a password. Please click the button below to be redirected to your
        organisation&apos;s login page.
      </p>
      <Button
        id="sso-login"
        colour="primary"
        block
        onClick={() => {
          window.document.location = `${process.env.REACT_APP_SSO_SIGN_IN_PAGE}?email=${email}`;
        }}
      >
        Continue to login
      </Button>
    </div>
    <div className="login-form__field">
      <Button
        block
        small
        onClick={() => {
          returnToLogin();
        }}
      >
        Back
      </Button>
    </div>
  </div>
);

SsoForm.propTypes = {
  email: PropTypes.string.isRequired,
  returnToLogin: PropTypes.func.isRequired,
};

export default SsoForm;
