import { useReducer } from 'react';

const INITIAL_STATE = {
  show_create_modal: false,
  show_update_modal: false,
  show_delete_modal: false,
  selected_item: null,
  items: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updatedField':
      return { ...state, [action.field]: action.value };
    default:
      return INITIAL_STATE;
  }
};

const useList = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const updateState = field => value => {
    dispatch({
      type: 'updatedField',
      field,
      value,
    });
  };

  return { state, updateState };
};

export default useList;
