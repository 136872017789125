import React, { useReducer, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Card, getLogo } from '../global-components';
import UserCheckForm from './components/partials/user-check-form';
import UsernamePasswordForm from './components/partials/username-password-form';
import RequestResetForm from './components/partials/request-reset-form';
import SsoForm from './components/partials/sso-form';
import Eula from './components/partials/eula';
import { GET_ORG_LOGO } from '../queries';
import PlatformColours from '../global-components/variables.scss';

const initialState = {
  activeForm: 'info',
  data: {
    customLogo: null,
  },
};

const reducer = (state, action) => {
  switch (action.status) {
    case 'info': {
      return { ...initialState };
    }
    case 'login': {
      return {
        activeForm: 'login',
        data: {
          email: action.payload.email,
          customLogo: action.payload.organisationBranding?.logo,
          codeRequired: action.payload.mfaStatus === 'true',
        },
      };
    }
    case 'sso': {
      return {
        activeForm: 'sso',
        data: {
          email: action.payload.email,
          customLogo: action.payload.organisationBranding?.logo,
        },
      };
    }
    case 'reset_password': {
      return {
        activeForm: 'reset_password',
        data: {
          email: action.payload.email,
          customLogo: action.payload.organisationBranding?.logo,
        },
      };
    }
    default:
      return state;
  }
};

const Login = ({ callback }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [clientLogo, setClientLogo] = useState(null);
  const [clientPrimaryColour, setClientPrimaryColour] = useState('');

  const params = new URLSearchParams(document.location.search);
  const client = params.get('client');
  const ssoError = params.get('fmerr');

  const { loading, data } = useQuery(GET_ORG_LOGO, {
    variables: {
      orgName: client,
    },
  });

  useEffect(() => {
    if (!loading) {
      // set logo
      if (data?.organisationBranding?.logo) {
        setClientLogo(data.organisationBranding.logo);
      } else {
        setClientLogo(getLogo('atlasblack'));
      }

      // set primary colour
      if (data?.organisationBranding?.primaryColour) {
        setClientPrimaryColour(data.organisationBranding.primaryColour);
      } else {
        setClientPrimaryColour(PlatformColours.brandorange);
      }
    }
  }, [loading]);

  useEffect(() => {
    document.title = 'Atlas - Cyber';
  }, []);

  const processData = pData => {
    if (pData.ssoStatus) {
      dispatch({ status: 'sso', payload: pData });
    } else {
      dispatch({
        status: 'login',
        payload: pData,
      });
    }
  };

  const dispatchPasswordReset = email => {
    dispatch({
      status: 'reset_password',
      payload: { email, organisationBranding: { logo: state.data.customLogo } },
    });
  };

  const resetState = () => {
    dispatch({ status: 'info' });
  };

  return (
    <div className="login-page">
      <div className="login-page__content">
        <Card>
          <div className="login-page__content-centered">
            {!loading && clientLogo && (
              <img
                width="100%"
                className="login-page__logo"
                src={clientLogo}
                alt="logo"
              />
            )}
          </div>
          {state.activeForm === 'info' && (
            <UserCheckForm
              primaryColour={clientPrimaryColour}
              onDataReceived={e => processData(e)}
            />
          )}
          {ssoError && <p className="error-text">{ssoError}</p>}
          {state.activeForm === 'sso' && (
            <SsoForm {...state.data} returnToLogin={resetState} />
          )}
          {state.activeForm === 'login' && (
            <UsernamePasswordForm
              primaryColour={clientPrimaryColour}
              onLogin={callback}
              onPasswordResetRequest={dispatchPasswordReset}
              returnToLogin={resetState}
              {...state.data}
            />
          )}
          {state.activeForm === 'reset_password' && (
            <RequestResetForm {...state.data} returnToLogin={resetState} />
          )}
          <Eula />
        </Card>
      </div>
    </div>
  );
};

Login.propTypes = {
  callback: PropTypes.func,
};

export default Login;
