import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Button from '../Button';
import useStateReducer from '../Hooks/useStateReducer';
import { getInitialState } from './helpers';
import { Card } from '../Card';
import Fields from './fields';
import Steps from './steps';

const FormContainer = ({
  id,
  large,
  medium,
  title,
  steps,
  onClose,
  onSubmit,
}) => {
  const { state, updateState } = useStateReducer(getInitialState(steps));
  const [currentStep, setCurrentStep] = useState(0);
  const { register, handleSubmit } = useForm();
  const formParts = steps.map((step, i) => (
    <Fields
      key={`form-field-${i}`}
      register={register}
      state={state}
      fields={step.fields}
      changeCallback={updateState}
    />
  ));

  const submitValues = () => {
    onSubmit(state);
  };

  return (
    <div className="modal" id={id}>
      <div
        className={`modal__box ${large ? 'modal__box--large' : ''} ${
          medium ? 'modal__box--medium' : ''
        }`}
      >
        <Card title={title}>
          <div className="modal__box__content">
            <div id="step-form">
              <Steps
                steps={steps.filter(step => step.title).map(step => step.title)}
                activeStep={currentStep}
                onClick={setCurrentStep}
              />
              {formParts[currentStep]}
            </div>
          </div>
          <div className="modal__box__content__controls">
            <div className="modal__box__content__controls__control">
              <Button
                id="cancel-button"
                colour="grey"
                onClick={() => onClose(false)}
              >
                Cancel
              </Button>
            </div>
            {currentStep > 0 && (
              <div className="modal__box__content__controls__control">
                <Button
                  id="previous-button"
                  colour="blue"
                  onClick={e => {
                    e.preventDefault();
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  Previous
                </Button>
              </div>
            )}
            {currentStep < steps.length - 1 && (
              <div className="modal__box__content__controls__control">
                <Button
                  id="next-button"
                  colour="blue"
                  onClick={e => {
                    e.preventDefault();
                    setCurrentStep(currentStep + 1);
                  }}
                >
                  Next
                </Button>
              </div>
            )}
            <div className="modal__box__content__controls__control">
              <Button
                id="submit-button"
                colour="primary"
                type="submit"
                onClick={handleSubmit(submitValues)}
              >
                Submit
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

FormContainer.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default FormContainer;
