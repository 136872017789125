import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CheckBox,
  TextInput,
  PageSpinner,
  Select,
  useStateReducer,
} from '..';
import {
  GET_DOCUMENTS,
  CREATE_DOCUMENT,
  GET_GENDOC_TYPES_LIST,
} from './queries';
import { GET_ORGANISATION_USERS } from '../../hub-app/pages/organisations/queries';

const CreateDocumentModal = ({ onClose, organisationId }) => {
  const path = window.location.pathname;
  const app = path.split('/')[1];
  const { state, updateState } = useStateReducer({
    title: '',
    sub_type: '',
    checked_user_ids: [],
  });

  const { loading: userLoading, data: userData, error: userError } = useQuery(
    GET_ORGANISATION_USERS,
    {
      variables: {
        organisationId: parseInt(organisationId),
      },
    }
  );

  const {
    loading: docsListLoading,
    data: docsListData,
    error: docsListError,
  } = useQuery(GET_GENDOC_TYPES_LIST, {
    variables: {
      application: app,
      organisationId: parseInt(organisationId),
    },
  });

  const [addDocument] = useMutation(CREATE_DOCUMENT, {
    refetchQueries: [
      {
        query: GET_DOCUMENTS,
        variables: {
          application: app,
          organisationId: parseInt(organisationId),
        },
      },
    ],
    onError: e => {
      e.graphQLErrors.forEach(error => {
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT });
      });
    },
    onCompleted: () =>
      toast.success('Document created.', {
        position: toast.POSITION.TOP_RIGHT,
      }),
  });

  const { register, handleSubmit, errors } = useForm();

  const users = userData?.organisationUsers;

  const onSubmit = () => {
    addDocument({
      variables: {
        newDocument: {
          organisationId: parseInt(organisationId),
          application: app,
          title: state.title,
          subType: state.sub_type,
          documentType: parseInt(state.type),
          allowedViewers: state.checked_user_ids,
        },
      },
    }).then(res => {
      if (res) {
        onClose(false);
      }
    });
  };

  if (userLoading || userError || docsListLoading || docsListError) {
    return <PageSpinner />;
  }

  return (
    <div className="modal">
      <div className="modal__box">
        <Card title="Create New Document">
          <div className="modal__box__content">
            <div className="modal__box__content__input">
              <Select
                name="type"
                type="number"
                id="type-input"
                label="Document Type"
                defaultValue={state.type}
                onChange={e => updateState('type')(e.target.value)}
                options={docsListData?.gendocTypesList.map(item => ({
                  value: item.id,
                  label: item.typeName,
                }))}
                forwardedRef={register({ required: true })}
                error={errors.type}
              />
            </div>
            <div className="modal__box__content__input">
              <TextInput
                name="title"
                id="title-input"
                label="Title"
                type="text"
                value={state.title}
                forwardedRef={register({ required: true })}
                onChange={e => updateState('title')(e.target.value)}
              />
            </div>
            <div className="modal__box__content__input">
              <TextInput
                name="sub-type"
                id="sub-type-input"
                label="Sub-type"
                type="text"
                value={state.sub_type}
                forwardedRef={register({ required: true })}
                onChange={e => updateState('sub_type')(e.target.value)}
              />
            </div>
            <h4>Contacts</h4>
            <div className="modal__box__content__input modal__box__content__input--scrolling">
              {users.map(user => (
                <CheckBox
                  id={`user-${user.id}`}
                  key={`user-${user.id}`}
                  name={`user-${user.id}`}
                  label={`${user.firstName} ${user.lastName}`}
                  checked={state.checked_user_ids.includes(user.id)}
                  onChange={e => {
                    if (e.target.checked) {
                      updateState('checked_user_ids')([
                        ...state.checked_user_ids,
                        user.id,
                      ]);
                    } else {
                      updateState('checked_user_ids')(
                        state.checked_user_ids.filter(id => id !== user.id)
                      );
                    }
                  }}
                />
              ))}
            </div>
          </div>
          <div className="modal__box__content__controls">
            <div className="modal__box__content__controls__control">
              <Button
                id="cancel-button"
                colour="grey"
                onClick={() => onClose(false)}
              >
                Cancel
              </Button>
            </div>
            <div className="modal__box__content__controls__control">
              <Button
                id="create-button"
                colour="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Create
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

CreateDocumentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  organisationId: PropTypes.string.isRequired,
};

export default CreateDocumentModal;
