import gql from 'graphql-tag';

const GET_OBJECTIVES = gql`
  query GetObjectives {
    objectives {
      id
      name
    }
  }
`;

const GET_ORG_LOGO = gql`
  query GetOrgLogo($orgName: String) {
    organisationBranding( orgName: $orgName ) {
      logo,
      primaryColour
    }
  }
`;

export { GET_OBJECTIVES, GET_ORG_LOGO };
