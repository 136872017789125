import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import Icon from '../Icon';
import 'react-circular-progressbar/dist/styles.css';
import PlatformColors from '../variables.scss';

const inital = {
  pathColor: PlatformColors.progressinitialpath,
  textColor: PlatformColors.progressinitialtext,
  trailColor: PlatformColors.progressinitialtrail,
  backgroundColor: PlatformColors.progressinitialbg,
};

const progress = {
  pathColor: PlatformColors.progressprogresspath,
  textColor: PlatformColors.progressprogresstext,
  trailColor: PlatformColors.progressprogresstrail,
  backgroundColor: PlatformColors.progressprogressbg,
};

const completed = {
  pathColor: PlatformColors.progresscompletedpath,
  textColor: PlatformColors.progresscompletedtext,
  trailColor: PlatformColors.progresscompletedtrail,
  backgroundColor: PlatformColors.progresscompletedbg,
};

const fiftyPercent = {
  pathColor: PlatformColors.progressfiftypcpath,
  textColor: PlatformColors.progressfiftypcpath,
  trailColor: PlatformColors.progressfiftypcpath,
  backgroundColor: PlatformColors.progressfiftypcpath,
};

const ProgressProvider = ({ valueEnd, children }) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    setValue(valueEnd);
  }, [valueEnd]);

  return children(value);
};

const Gauge = ({ percentage, gaugeLabel, reversed }) => {
  let palette = inital;
  if (!reversed) {
    if (percentage > 0 && percentage < 100) {
      palette = progress;
    } else if (percentage === 100) {
      palette = completed;
    }
  } else if (percentage >= 0 && percentage < 50) {
    palette = fiftyPercent;
  } else if (percentage >= 50 && percentage <= 90) {
    palette = progress;
  } else {
    palette = completed;
  }

  return (
    <ProgressProvider valueEnd={percentage}>
      {value => (
        <CircularProgressbarWithChildren
          className="progress-gauge__gauge"
          value={value}
          styles={buildStyles(palette)}
          background
          strokeWidth={5}
        >
          <svg viewBox="0 0 500 500">
            <g id="UrTavla">
              <circle cx="250" cy="250" r="150" fill="white" />
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                fill={PlatformColors.progressproviderfill}
                fontSize="75px"
                fontWeight="bold"
                dy=".3em"
              >
                {gaugeLabel || `${percentage}%`}
              </text>
            </g>
          </svg>
        </CircularProgressbarWithChildren>
      )}
    </ProgressProvider>
  );
};

Gauge.propTypes = {
  percentage: PropTypes.number,
  reversed: PropTypes.bool,
  gaugeLabel: PropTypes.string,
};

const LockedGauge = () => (
  <CircularProgressbarWithChildren
    value={0}
    className="progress-gauge__gauge"
    styles={buildStyles(inital)}
  >
    <Icon name="Lock" size={42} colour="grey" />
  </CircularProgressbarWithChildren>
);

const ProgressGauge = ({ label, percentage, gaugeLabel, locked, reversed }) => (
  <div className="progress-gauge">
    {locked ? (
      <LockedGauge />
    ) : (
      <Gauge
        percentage={percentage}
        reversed={reversed}
        gaugeLabel={gaugeLabel}
      />
    )}
    {label && <label className="progress-gauge__label">{label}</label>}
  </div>
);

ProgressGauge.propTypes = {
  label: PropTypes.string,
  percentage: PropTypes.number,
  locked: PropTypes.bool,
  reversed: PropTypes.bool,
  gaugeLabel: PropTypes.string,
};

export default ProgressGauge;
