import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { TextInput, Button } from '../../../global-components';

const GET_STATUS = gql`
  query GetStatus($email: String!) {
    mfaStatus(email: $email)
    organisationBranding(email: $email) {
      logo
      secondaryColour
    }
    ssoStatus(email: $email)
  }
`;

const UserCheckForm = ({ primaryColour, onDataReceived }) => {
  const [email, updateEmail] = useState('');

  const [getStatus, { data }] = useLazyQuery(GET_STATUS);

  // eslint-disable-next-line no-shadow
  const onSubmit = ({ email }) => {
    getStatus({
      variables: {
        email,
      },
    });
  };

  const { register, handleSubmit, errors } = useForm();

  if (data) {
    onDataReceived({
      ...data,
      email,
    });
  }

  return (
    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="login-form__field">
        <TextInput
          type="email"
          name="email"
          id="email-input"
          label="Email"
          value={email}
          onChange={e => updateEmail(e.target.value)}
          forwardedRef={register({ required: true })}
          error={errors.email}
        />
      </div>
      <div className="login-form__field">
        <Button
          id="btn-login"
          style={{ backgroundColor: primaryColour, color: 'white' }}
          block
        >
          Login
        </Button>
      </div>
    </form>
  );
};

UserCheckForm.propTypes = {
  primaryColour: PropTypes.string,
  onDataReceived: PropTypes.func.isRequired,
};

export default UserCheckForm;
