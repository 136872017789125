import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { navigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  TextInput,
  Button,
  Card,
  getLogo,
  useStateReducer,
} from '../global-components';

const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $email: String!
    $token: String!
    $newPassword1: String!
    $newPassword2: String!
  ) {
    resetPassword(
      email: $email
      token: $token
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
    }
  }
`;

const ResetPassword = () => {
  const { state, updateState } = useStateReducer({
    email: '',
    newPassword1: '',
    newPassword2: '',
  });

  const { register, handleSubmit, errors } = useForm();

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onError: e => {
      e.graphQLErrors.forEach(error => {
        error.message
          .split('|')
          .forEach(item =>
            toast.error(item, { position: toast.POSITION.TOP_RIGHT })
          );
      });
    },
    onCompleted: () => {
      toast.success('Password reset.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate('/');
    },
  });

  const vars = window.location.pathname.split('/');

  if (vars.length !== 3) {
    return (
      <>
        <h1>Error: invalid URL</h1>
      </>
    );
  }
  const token = vars[2];

  const onReset = () => {
    resetPassword({
      variables: {
        token,
        ...state,
      },
    });
  };

  // The password reset page requires Atlas Black
  const logoName = window.location.pathname.includes('reset_password')
    ? 'atlasblack'
    : 'mitigate';

  return (
    <div className="login-page">
      <div className="login-page__content">
        <Card>
          <div className="login-page__content-centered">
            <img
              className="login-page__logo"
              src={getLogo(logoName)}
              alt="logo"
            />
          </div>
          <form
            className="reset-form"
            type="post"
            onSubmit={handleSubmit(onReset)}
          >
            <div className="login-form__field">
              <TextInput
                type="email"
                name="email"
                id="email-input"
                label="Email"
                value={state.email}
                onChange={e => updateState('email')(e.target.value)}
                forwardedRef={register({ required: true })}
                error={errors.email}
              />
            </div>
            <div className="login-form__field">
              <TextInput
                type="password"
                name="password1"
                id="password-1-input"
                label="New password"
                value={state.newPassword1}
                onChange={e => {
                  updateState('newPassword1')(e.target.value);
                }}
                forwardedRef={register({ required: true })}
                error={errors.password1}
              />
            </div>

            <div className="login-form__field">
              <TextInput
                type="password"
                name="password2"
                id="password-2-input"
                label="Confirm password"
                value={state.newPassword2}
                onChange={e => {
                  updateState('newPassword2')(e.target.value);
                }}
                forwardedRef={register({ required: true })}
                error={errors.password2}
              />
            </div>

            <div className="login-form__field">
              <Button colour="primary" block>
                Set Password
              </Button>
            </div>

            <div className="login-form__field">
              <Button
                block
                small
                onClick={e => {
                  e.preventDefault();
                  navigate('/');
                }}
              >
                Return to login
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
