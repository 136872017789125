import { useReducer } from 'react';

const useStateReducer = initialState => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'updatedField':
        return { ...state, [action.field]: action.value };
      default:
        return initialState;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const updateState = field => value => {
    dispatch({
      type: 'updatedField',
      field,
      value,
    });
  };

  return { state, updateState };
};

export default useStateReducer;
