import React from 'react';

const Eula = () => (
  <p className="eula-tag">
    By using this application you are agreeing to abide by our{' '}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://mitigate-hub-live.s3.eu-west-2.amazonaws.com/external/CitationcyberhubEULA.pdf"
    >
      EULA
    </a>
  </p>
);

export default Eula;
