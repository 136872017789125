import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, TextInput } from '../../../global-components';

const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($username: String!) {
    requestPasswordReset(username: $username) {
      success
    }
  }
`;

const RequestResetForm = ({ email, returnToLogin }) => {
  const [username, setUsername] = useState(email);
  const [resetRecaptchaPassed, setResetRecaptchaPassed] = useState(false);

  const [requestPasswordReset] = useMutation(REQUEST_PASSWORD_RESET, {
    onCompleted: () => {
      toast.info(
        'If an account was found you will receive a password reset email.',
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      returnToLogin();
    },
    onError: e => {
      e.graphQLErrors.forEach(error => {
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT });
      });
    },
  });

  const handleForgot = () => {
    if (resetRecaptchaPassed) {
      requestPasswordReset({
        variables: {
          username,
        },
      });
    }
  };

  const { register, handleSubmit, errors } = useForm();

  return (
    <form className="reset-form" onSubmit={handleSubmit(handleForgot)}>
      <h4>Reset Password</h4>
      <div className="login-form__field">
        <TextInput
          type="email"
          name="forgotemail"
          id="forgot-email-input"
          label="Email"
          value={username}
          onChange={e => {
            setUsername(e.target.value);
          }}
          forwardedRef={register({ required: true })}
          error={errors.forgotemail}
        />
      </div>

      <ReCAPTCHA
        sitekey="6LeX6wwUAAAAALzJYbkD9rpVbnPWfnOJeiflt33l"
        onExpired={() => setResetRecaptchaPassed(false)}
        onChange={() => {
          setResetRecaptchaPassed(true);
        }}
      />

      <div className="login-form__field">
        <Button colour="pink" block disabled={!resetRecaptchaPassed}>
          Reset Password
        </Button>
      </div>

      <div className="login-form__field">
        <Button
          block
          small
          onClick={e => {
            e.preventDefault();
            returnToLogin();
          }}
        >
          Back
        </Button>
      </div>
    </form>
  );
};

RequestResetForm.propTypes = {
  returnToLogin: PropTypes.func.isRequired,
  email: PropTypes.string,
};

RequestResetForm.defaultProps = {
  email: '',
};

export default RequestResetForm;
