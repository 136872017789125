import React, { Component } from 'react';
import { Card } from '../Card';
import Button from '../Button';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error.message };
  }

  // If we ever want to log errors to an external service
  // componentDidCatch(error, info) {
  // // Example "componentStack":
  // //   in ComponentThatThrows (created by App)
  // //   in ErrorBoundary (created by App)
  // //   in div (created by App)
  // //   in App
  // logErrorToMyService(error, info.componentStack);
  // }

  render() {
    const { hasError, errorMessage, children } = this.state;
    if (hasError) {
      return (
        <Card>
          <div className="wrapper">
            <h2 className="test">Oops, something went wrong...</h2>
            <br />
            <br />
            <h4 className="error-boundary-text">Error: {errorMessage} </h4>
            <Button
              colour="primary"
              onClick={() => {
                this.setState({ hasError: false });
                window.location.href = '/';
              }}
            >
              Go Home
            </Button>
          </div>
        </Card>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
