import React from 'react';
import PropTypes from 'prop-types';
import {
  Cell,
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Text,
} from 'recharts';

import { colours, getDefaultTableAxis } from './helpers';
import PlatformColors from '../variables.scss';

const vulnColours = {
  Critical: PlatformColors.piecritical,
  High: PlatformColors.piehigh,
  Medium: PlatformColors.piemedium,
  Low: PlatformColors.pielow,
  Info: PlatformColors.pieinfo,
};

const xcemVulnColours = {
  Critical: PlatformColors.xcemcritical,
  High: PlatformColors.xcemhigh,
  Medium: PlatformColors.xcemmedium,
  Low: PlatformColors.xcemlow,
  Info: PlatformColors.xceminfo,
};

const PieChartWrapper = ({
  data,
  dataLabel,
  dataKey,
  onClick,
  vulns,
  xcemMode,
  customColours,
  isHollow,
  legend = true,
  tooltip = true,
}) => {
  const { defaultLabel, defaultKeys } = getDefaultTableAxis(data);

  /* istanbul ignore next */
  const handleClick = activePayload => {
    const { payload } = activePayload;
    onClick(payload);
  };

  if (data.length === 0) {
    return <Text>No data available</Text>;
  }

  //* Start with default coloours, then if 'vuln mode is active
  //* we choose stock or XCEM compliant palette.
  let pieColours = colours;
  if (vulns) {
    pieColours = xcemMode ? xcemVulnColours : vulnColours;
  } else if (customColours) {
    pieColours = customColours;
  }

  return (
    <ResponsiveContainer width="99%" minHeight={200}>
      <PieChart>
        <Pie
          data={data}
          nameKey={dataLabel || defaultLabel}
          dataKey={dataKey || defaultKeys[0]}
          innerRadius={isHollow && 55}
          outerRadius={isHollow && 80}
          onClick={
            /* istanbul ignore next */
            e => onClick && handleClick(e.payload)
          }
        >
          {data.map((entry, index) => {
            if (vulns) {
              return (
                <Cell key={`cell-${index}`} fill={pieColours[entry.key]} />
              );
            }

            return <Cell key={`cell-${index}`} fill={pieColours[index]} />;
          })}
        </Pie>
        {legend && <Legend />}
        {tooltip && <Tooltip />}
      </PieChart>
    </ResponsiveContainer>
  );
};

PieChartWrapper.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataLabel: PropTypes.string,
  dataKey: PropTypes.string,
  onClick: PropTypes.func,
  vulns: PropTypes.bool,
  xcemMode: PropTypes.bool,
  customColours: PropTypes.arrayOf(PropTypes.string),
  isHollow: PropTypes.bool,
  legend: PropTypes.bool,
  tooltip: PropTypes.bool,
};

export default PieChartWrapper;
