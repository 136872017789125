import React from 'react';
import PropTypes from 'prop-types';

const Steps = ({ steps, activeStep, onClick }) => (
  <div className="steps">
    {steps.map((step, i) => (
      <div
        key={`step-${i}`}
        className={
          activeStep === i ? 'steps__step steps__step--active' : 'steps__step'
        }
        onClick={() => onClick(i)}
      >
        <span className="steps__step__badge">{i + 1}</span>
        <h4>{step}</h4>
      </div>
    ))}
  </div>
);

Steps.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
  onClick: PropTypes.func,
};

export default Steps;
