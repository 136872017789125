import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon';
import { CheckBox } from '../Input';

const SelectableTable = ({
  headers,
  columns,
  fnStateToggle,
  widget,
  scroll,
}) => {
  const [selected, setSelected] = useState(false);
  const viewable = false;

  return (
    <div
      className={`data-table ${widget && 'data-table--widget'} ${scroll &&
        'data-table--scroll'}`}
    >
      <table className="data-table__table">
        <thead className="data-table__header">
          <tr className="data-table__header-row">
            {headers.map((header, index) =>
              header === '[x]' ? (
                <th className="data-table__header-cell" key={`th-${index}`}>
                  <CheckBox
                    name="headerCheckbox"
                    id="headerCheckbox"
                    onChange={() => {
                      fnStateToggle(!selected);
                      setSelected(!selected);
                    }}
                    checked={selected}
                  />
                </th>
              ) : (
                <th className="data-table__header-cell" key={`th-${index}`}>
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody className="data-table__body">
          {columns.map((row, index) => (
            <tr
              className={classNames('data-table__body-row', {
                'data-table__body-row--clickable': viewable,
              })}
              key={`tr-${index}`}
            >
              {Object.values(row).map((value, valueIndex) => {
                if (typeof value === 'object') {
                  if (
                    value !== null &&
                    Object.prototype.hasOwnProperty.call(value, 'align')
                  ) {
                    if (value.align === 'center') {
                      return (
                        <td
                          className="data-table__body-cell data-table__body-cell--centered"
                          key={`td-${index}-${valueIndex}`}
                        >
                          {value.text}
                        </td>
                      );
                    }
                    return (
                      <td
                        className="data-table__body-cell data-table__body-cell--flex"
                        key={`td-${index}-${valueIndex}`}
                      >
                        {value.text}
                      </td>
                    );
                  }
                  return (
                    <td
                      className="data-table__body-cell"
                      key={`td-${index}-${valueIndex}`}
                    >
                      {value}
                    </td>
                  );
                }
                return (
                  <td
                    className="data-table__body-cell"
                    key={`td-${index}-${valueIndex}`}
                  >
                    {value}
                  </td>
                );
              })}
              {viewable && (
                <td className="data-table__body-cell data-table__body-cell--align-right">
                  <Icon name="ArrowRight" colour="primary" />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

SelectableTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.array,
  fnStateToggle: PropTypes.func,
  widget: PropTypes.bool,
  scroll: PropTypes.bool,
};

export default SelectableTable;
