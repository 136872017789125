import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as FeatherIcons from 'react-feather';

import CalendarDate from './CalendarDate.svg';
import CalendarExpiry from './CalendarExpiry.svg';
import CourseOverview from './CourseOverview.svg';
import MitiCheck from './MitiCheck.svg';
import Phishing from './phishing.svg';

import Mac from './Mac.svg';
import Linux from './Linux.svg';
import Windows from './Windows.svg';
import Unidentified from './Unidentified.svg';
import CitationError from './CitationError.svg';

import CitationIconSet from './citationicons';

//* BASE ICONS
//* These were obtained from somewhere by the original developer.
//* They are used in various places mostly CyberIntel as it needs
//* to show icons for the available operating systems out there.
const baseIconSet = {
  calendardate: CalendarDate,
  calendarexpiry: CalendarExpiry,
  courseoverview: CourseOverview,
  miticheck: MitiCheck,
  phishing: Phishing,
  mac: Mac,
  linux: Linux,
  windows: Windows,
  unidentified: Unidentified,
  unknown: Unidentified,
  noentryerror: CitationError,
};

//* ===========================================================================
//* COMPONENT: CoreIcon
//* SCOPE:     Private
//* DOES:      Used to render an icon given its image data URL.
const CoreIcon = ({ imageUrl, colour, clickable, withMargin }) => (
  <span
    className={classNames(
      'icon',
      { [`icon--${colour}`]: colour },
      { 'icon--clickable': clickable },
      { 'icon--margin': withMargin }
    )}
  >
    <img alt="custom-icon" src={imageUrl} style={{ width: '20px' }} />
  </span>
);
CoreIcon.propTypes = {
  imageUrl: PropTypes.string,
  colour: PropTypes.string,
  clickable: PropTypes.bool,
  withMargin: PropTypes.bool,
};

//* ===========================================================================
//* COMPONENT: Icon
//* SCOPE:     Exported
//* DOES:      Renders an icon from one of three internal sets:
//*            Citation, Base, Feather, in that order. Any icon with the same
//*            name as a default FeatherIcon will automaticlly be shown.
//*            If no other match is found, we use "Meh" as the default.
const Icon = ({
  name = 'meh',
  colour,
  clickable,
  size,
  withBorder,
  withMargin,
}) => {
  const lcName = name.toLowerCase();
  //* =========================
  //* CITATION CUSTOM SVG ICON?
  //* =========================
  if (lcName in CitationIconSet) {
    return (
      <CoreIcon
        imageUrl={CitationIconSet[lcName]}
        colour={colour}
        clickable={clickable}
        withMargin={withMargin}
      />
    );
  }
  //* ===========================
  //* BASE ICON (O/S names etc.)?
  //* ===========================
  if (lcName in baseIconSet) {
    return (
      <CoreIcon
        imageUrl={baseIconSet[lcName]}
        colour={colour}
        clickable={clickable}
        withMargin={withMargin}
      />
    );
  }
  //* ===========================================
  //* DEFAULT: Render a FeatherIcon with a border
  //* if that was specified in the supplied props.
  const FeatherIcon = FeatherIcons[name];
  if (withBorder) {
    return (
      <div className="icon-border">
        <span
          className={classNames(
            'icon',
            { [`icon--${colour}`]: colour },
            { 'icon--clickable': clickable }
          )}
        >
          <FeatherIcon size={size} />
        </span>
      </div>
    );
  }
  return (
    <span
      className={classNames(
        'icon',
        { [`icon--${colour}`]: colour },
        { 'icon--clickable': clickable },
        { 'icon--margin': withMargin }
      )}
    >
      <FeatherIcon size={size} />
    </span>
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  colour: PropTypes.string,
  clickable: PropTypes.bool,
  size: PropTypes.number,
  withBorder: PropTypes.bool,
  withMargin: PropTypes.bool,
};

export default Icon;
