import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  return (
    <TabContext value={activeTab}>
      <TabList onChange={(e, tab) => setActiveTab(tab)}>
        {tabs.map(tab => (
          <Tab label={tab.label} value={tab.value} />
        ))}
      </TabList>
      {tabs.map(tab => (
        <TabPanel value={tab.value}>{tab.component}</TabPanel>
      ))}
    </TabContext>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.objectOf({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
};

export default Tabs;
