const getInitialState = steps => {
  const initialState = {};
  steps.forEach(step =>
    step.fields.forEach(field => {
      if (field.exclude !== true) {
        if (Object.prototype.hasOwnProperty.call(initialState, field.name)) {
          initialState[field.name] = [
            ...initialState[field.name],
            field.default,
          ];
        } else {
          initialState[field.name] = field.default;
        }
      }
    })
  );
  return initialState;
};

const conditionalAnswerMatched = (state, key, value) => state[key] === value;

export { getInitialState, conditionalAnswerMatched };
