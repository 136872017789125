import React from 'react';
import PropTypes from 'prop-types';

const HeaderCard = ({ header, body, footer }) => (
  <div className="card card--header-card">
    <div className="card--header-card__header">{header}</div>
    <div className="card--header-card__body">{body}</div>
    {footer && <div className="card--header-card__footer">{footer}</div>}
  </div>
);

HeaderCard.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  body: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
};

export default HeaderCard;
