import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

const AppSpinner = () => (
  <div className="loading-spinner">
    <ReactLoading color="#ff9613" type="cylon" />
    <h3>App Loading...</h3>
  </div>
);

const PageSpinner = ({ message }) => (
  <div className="loading-spinner loading-spinner--page">
    <ReactLoading color="#ff9613" type="cylon" />
    {message && <h4>{message}</h4>}
  </div>
);

PageSpinner.propTypes = {
  message: PropTypes.string,
};

export { AppSpinner, PageSpinner };
