import React from 'react';
import PropTypes from 'prop-types';

import { CheckBox, TextInput, Select, DateInput } from '../Input';
import ToggleInput from '../Toggle';
import { conditionalAnswerMatched } from './helpers';

const Fields = ({ register, state, fields, changeCallback }) => {
  const parsedFields = fields.map((field, i) => {
    if (
      !Object.prototype.hasOwnProperty.call(field, 'conditionalKey') ||
      conditionalAnswerMatched(
        state,
        field.conditionalKey,
        field.conditionalValue
      )
    ) {
      switch (field.type) {
        case 'text':
        case 'textarea':
        case 'email':
        case 'number':
          return (
            <div
              className="modal__box__content__input"
              key={`${field.name}-${i}`}
            >
              <TextInput
                name={field.name}
                id={`${field.name}-${i}`}
                label={field.label}
                type={field.type}
                value={state[field.name] || field.default}
                forwardedRef={
                  register && register({ required: field.required })
                }
                onChange={e => changeCallback(field.name)(e.target.value)}
              />
            </div>
          );
        case 'checkbox':
          return (
            <div
              className="modal__box__content__input"
              key={`${field.name}-${i}`}
            >
              <CheckBox
                id={`${field.name}-${i}`}
                name={`checkbox-${field.name}-${i}`}
                label={field.label}
                checked={
                  Array.isArray(state[field.name])
                    ? state[field.name].includes(field.default)
                    : state[field.name] === field.default
                }
                onChange={e => {
                  if (e.target.checked) {
                    if (Array.isArray(state[field.name])) {
                      changeCallback(field.name)([
                        ...state[field.name],
                        field.default,
                      ]);
                    } else {
                      changeCallback(field.name)(field.default);
                    }
                  } else if (Array.isArray(state[field.name])) {
                    changeCallback(field.name)(
                      state[field.name].filter(id => id !== field.default)
                    );
                  } else {
                    changeCallback(field.name)(null);
                  }
                }}
              />
            </div>
          );
        case 'toggle':
          return (
            <div
              className="modal__box__content__input"
              key={`${field.name}-${i}`}
            >
              <ToggleInput
                id={`${field.name}-${i}`}
                label={field.label}
                defaultChecked={state[field.name] || field.default}
                onChange={e => changeCallback(field.name)(e.target.checked)}
              />
            </div>
          );
        case 'date':
          return (
            <div
              className="modal__box__content__input"
              key={`${field.name}-${i}`}
            >
              <DateInput
                name={field.name}
                id={`${field.name}-${i}`}
                label={field.label}
                value={state[field.name] || field.default}
                onChange={date => changeCallback(field.name)(date)}
              />
            </div>
          );
        case 'select':
          return (
            <div
              className="modal__box__content__input"
              key={`${field.name}-${i}`}
            >
              <Select
                name={field.name}
                id={`${field.name}-${i}`}
                label={field.label}
                value={state[field.name] || field.default}
                forwardedRef={
                  register &&
                  register({
                    required: !!field.required,
                  })
                }
                onChange={e => changeCallback(field.name)(e.target.value)}
                options={field.options.map(option => ({
                  value: option.value,
                  label: option.label,
                }))}
              />
            </div>
          );
        default:
          return (
            <>
              <div className="overview__form__divider" />
              <h2 className="overview__form__heading">{field.name}</h2>
            </>
          );
      }
    }
    return <></>;
  });
  return <div className="form-field-group">{parsedFields}</div>;
};

Fields.propTypes = {
  register: PropTypes.func,
  state: PropTypes.object,
  fields: PropTypes.array,
  changeCallback: PropTypes.func,
};

export default Fields;
