import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({
  style,
  children,
  colour,
  disabled,
  id,
  onClick,
  small,
  large,
  block,
  noRadius,
  transparent,
  type,
}) => (
  <button
    style={style}
    id={id}
    className={classNames(
      'btn',
      { [`btn--${colour}`]: colour },
      { 'btn--small': small },
      { 'btn--large': large },
      { 'btn--block': block },
      { 'btn--no-radius': noRadius },
      { 'btn--transparent': transparent }
    )}
    onClick={onClick}
    disabled={disabled}
    type={type}
  >
    <span className="btn__label">{children}</span>
  </button>
);

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  style: PropTypes.object,
  colour: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  large: PropTypes.bool,
  block: PropTypes.bool,
  noRadius: PropTypes.bool,
  transparent: PropTypes.bool,
  type: PropTypes.string,
};

export default Button;
