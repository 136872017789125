import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-circular-progressbar/dist/styles.css';

const TimeFormat = secNum => {
  let hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - hours * 3600) / 60);
  let seconds = secNum - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
};

const Timer = ({ seconds, onTimeOut }) => {
  const [time, setTime] = useState(seconds);

  useEffect(() => {
    let timeout;
    if (time > 0) {
      timeout = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    } else {
      onTimeOut();
    }

    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [time]);

  const percentage = Math.ceil((time / seconds) * 100);

  let background = '#4caf50';
  let colour = '#ffffff';

  if (percentage > 33 && percentage <= 60) {
    background = '#ff9800';
    colour = '#000000';
  }

  if (percentage <= 33 && percentage >= 0) {
    background = '#f44336';
    colour = '#000000';
  }
  return (
    <div className="timer">
      <div className="timer__text" style={{ color: colour }}>
        {TimeFormat(time)}
      </div>
      <div
        className="timer__bar"
        style={{
          width: `${percentage}%`,
          backgroundColor: background,
        }}
      />
    </div>
  );
};
Timer.propTypes = {
  seconds: PropTypes.number,
  onTimeOut: PropTypes.func,
};

export default Timer;
