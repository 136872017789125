import React, { useState } from 'react';
import PropTypes from 'prop-types';

const TabsOld = ({ tabs, children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div id="tab-container">
        {tabs.map((tab, i) => (
          <button
            id={`tab-button-${i}`}
            key={`tab-button-${i}`}
            className="tab-button"
            onClick={() => setActiveTab(i)}
            disabled={i === activeTab}
          >
            {tab}
          </button>
        ))}
      </div>
      {children[activeTab]}
    </div>
  );
};

TabsOld.propTypes = {
  tabs: PropTypes.array,
  children: PropTypes.array,
};

export default TabsOld;
