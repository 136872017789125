import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Card } from '../Card';
import Button from '../Button';

const DeleteItemModal = ({ item, title, onClose, queries }) => {
  const [deleteItem] = useMutation(queries.delete, {
    refetchQueries: [
      {
        query: queries.refetch,
        variables: queries.variables,
      },
    ],
    onError: e => {
      e.graphQLErrors.forEach(error => {
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT });
      });
    },
    onCompleted: () => {
      toast.success('Successfully deleted.', {
        position: toast.POSITION.TOP_RIGHT,
      });

      if (queries.redirect) {
        navigate(queries.redirect);
      }
    },
  });

  const onDeleteItem = () => {
    deleteItem({
      variables: {
        id: parseInt(item),
      },
    });
    onClose();
  };

  return (
    <div className="modal">
      <div className="modal__box">
        <Card title={title}>
          <div className="modal__box__content">
            <p className="modal__box__content__text">
              Are you sure, this operation is irreversible?
            </p>
            <div className="modal__box__content__controls">
              <div className="modal__box__content__controls__control">
                <Button colour="grey" onClick={() => onClose(false)}>
                  Cancel
                </Button>
              </div>
              <div className="modal__box__content__controls__control">
                <Button colour="red" onClick={() => onDeleteItem()}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

DeleteItemModal.propTypes = {
  item: PropTypes.number,
  title: PropTypes.string,
  queries: PropTypes.object,
  onClose: PropTypes.func,
};

export default DeleteItemModal;
