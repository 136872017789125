import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Cookies from 'js-cookie';
import { useIdleTimer } from 'react-idle-timer/dist/modern';
import Loadable from 'react-loadable';
import { Router, navigate } from '@reach/router';
import { ToastContainer , toast } from 'react-toastify';
import getClient from './client';
import { UserWrapper } from './UserWrapper';
import { Login, ResetPassword } from './login';
import { AppSpinner } from './global-components';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImpersonateBar from './ImpersonateBar';


const setupWsClient = () => {
    const url = `${process.env.REACT_APP_WS_URL}/cc/socket-server/`
    // chatSocket SHOULD be pushed down the component stack as avm_
    // context wrapper in the same was as the user object.
    const chatSocket = new WebSocket(url);
    //
    // RECEIVE HANDLER
    //  => setTimout( //send 'ping', 60) keeps groups alive on server
    //
    chatSocket.onmessage = (e) => {
      // e.data.type => 'toast'
      // e.data.success => true or false
      // e.data.message => string message data
      const data = JSON.parse(e.data);
      // console.log("***JSON***", data);
      if (data.type === 'asset.updated') {
        const browserPath = window.location.pathname;
        if (browserPath === data.url) {
          toast.success(
            `Reload asset page: ${data.url}`,
            {position: toast.POSITION.TOP_RIGHT}
          );
        }
      }
      if (data.type === 'toast') {
        if (data.status) {
          toast.success(
            data.message,
            {position: toast.POSITION.TOP_RIGHT}
          );
        }
        else {
          toast.error(
            data.message,
            {position: toast.POSITION.TOP_RIGHT}
          );
        }
      }
    }
  return chatSocket;
};

function App() {
  const [jwt, setJwt] = useState(Cookies.get('mitigate-jwt'));
  const expires = Cookies.get('mitigate-expires');

  let client = getClient();
  setupWsClient();

  const updateJwt = (token, expiresIn) => {
    Cookies.set('mitigate-jwt', token, { secure: true });
    Cookies.set('mitigate-expires', expiresIn, { secure: true });
    setJwt(token);
    navigate('/');
  };

  const logout = () => {
    Cookies.remove('mitigate-jwt');
    Cookies.remove('mitigate-expires');
    sessionStorage.removeItem('brand-logo');
    setJwt(undefined);
    client = getClient();
    navigate('/');
  };

  if (
    expires === undefined ||
    expires < Math.round(new Date().getTime() / 1000)
  ) {
    if (jwt !== undefined) {
      logout();
    }
  }

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: () => {
      if (jwt) {
        logout();
      }
    },
    debounce: 500,
  });

  const AsyncHubApp = Loadable({
    loader: () => import('./hub-app'),
    loading: AppSpinner,
  });

  const AsyncLMSApp = Loadable({
    loader: () => import('./lms-app'),
    loading: AppSpinner,
  });

  const AsyncComplyApp = Loadable({
    loader: () => import('./comply-app'),
    loading: AppSpinner,
  });

  const AsyncHackApp = Loadable({
    loader: () => import('./hack-app'),
    loading: AppSpinner,
  });

  const AsyncCertApp = Loadable({
    loader: () => import('./cert-app'),
    loading: AppSpinner,
  });

  const AsyncIntelApp = Loadable({
    loader: () => import('./intel-app'),
    loading: AppSpinner,
  });

  const AsyncPhishApp = Loadable({
    loader: () => import('./phish-app'),
    loading: AppSpinner,
  });

  const AsyncConsultApp = Loadable({
    loader: () => import('./consult-app'),
    loading: AppSpinner,
  });

  const AsyncHealthApp = Loadable({
    loader: () => import('./health-app'),
    loading: AppSpinner,
  });

  const AsyncCyberSurveyApp = Loadable({
    loader: () => import('./hub-app/pages/cyberSurvey/index'),
    loading: AppSpinner,
  });

  const theme = createTheme({
    palette: {
      primary: { main: '#e6007e' },
      secondary: { main: '#14a38b' },
    },
    typography: {
      fontSize: 21,
      fontWeightRegular: 400,
      fontFamily: ['Avenir Next', 'sans-serif'].join(','),
    },
    components: {
      // Name of the component
      MuiTableHead: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            textTransform: 'uppercase',
            fontSize: '18px',
            backgroundColor: '#EEEEF2',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            '&:nth-of-type(even)': {
              backgroundColor: '#F6F6F8',
            },
          },
          head: {
            color: '#292A2B !important',
            backgroundColor: '#EEEEF2',
          },
          hover: {
            '&:hover': {
              backgroundColor: 'white !important',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          // Name of the slot
          head: {
            color: '#292A2B !important',
            backgroundColor: '#EEEEF2',
            fontWeight: 'bold',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '& .MuiTabs-flexContainer': {
              padding: '8px 0 0 8px',
              // paddingleft: '8px',
            },
            '& .MuiTabs-indicator': {
              display: 'none', // Hide the default indicator
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          // Name of the slot
          root: {
            backgroundColor: 'white',
            // Some CSS
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
            marginRight: '4px',
            '&.Mui-selected': {
              borderBottom: 0,
              boxShadow: '-2px 5px 10px rgba(0, 0, 0, 0.3)',
            },
            '&:not(.Mui-selected)': {
              bottom: '-10px',
            },
            ':first-child': {
              paddingLeft: '8px',
            },
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          // Name of the slot
          root: {
            backgroundColor: 'white',
            boxShadow: '-2px 5px 5px rgba(0, 0, 0, 0.3)',
            paddingTop: '12px',
            marginBottom: '16px',
          },
        },
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        {jwt === undefined ? (
          <>
            <Router>
              <Login callback={updateJwt} path="/*" />
              <SsoLogin callback={updateJwt} path="sso/login" />
              <ResetPassword path="reset_password/*" />
              <B2CLoginStart path="complete/b2c" />
              <B2CLoginDone callback={updateJwt} path="complete/b2c-done" />
            </Router>
          </>
        ) : (
          <UserWrapper logout={logout}>
            <ImpersonateBar/>
            <Router>
              <AsyncHubApp path="/*" />
              <AsyncLMSApp path="cyberlearn/*" />
              <AsyncComplyApp path="cybercomply/*" />
              <AsyncHackApp path="cyberhack/*" />
              <AsyncCertApp path="cybercert/*" />
              <AsyncIntelApp path="cyberintel/*" />
              <AsyncPhishApp path="cyberphish/*" />
              <AsyncConsultApp path="cyberconsult/*" />
              <AsyncHealthApp path="cyberhealth/*" />
              <AsyncCyberSurveyApp path="cybersurvey/*" />
              <B2CLoginStart path="complete/b2c" />
              <B2CLoginDone callback={updateJwt} path="complete/b2c-done" />
            </Router>
          </UserWrapper>
        )}
      </ThemeProvider>
    </ApolloProvider>
  );
}

const SsoLogin = props => {
  const { location, callback } = props;
  const params = new URLSearchParams(location.search.substring(1));
  if (params.get('dep_set')) {
    sessionStorage.setItem(
      'department_setup_required',
      params.get('dep_set') === 'False'
    );
  }

  callback(params.get('token'), params.get('expires_in'));
  return <AppSpinner />;
};

SsoLogin.propTypes = {
  location: PropTypes.object,
  callback: PropTypes.func,
};

// ====================================================================
// B2CLoginStart: remove any current session prior to API call.
// ====================================================================
const B2CLoginStart = () => {
  const url = new URL(process.env.REACT_APP_ATLAS_B2C_REDIRECT);
  const jwtToken = window.location.hash.slice(10);
  url.searchParams.append('id_token', jwtToken);
  window.location.href = url;
  return <></>;
};

const B2CLoginDone = props => {
  const { callback } = props;
  const params = new URLSearchParams(window.location.search.substring(1));
  if (params.get('dep_set')) {
    sessionStorage.setItem(
      'department_setup_required',
      params.get('dep_set') === 'False'
    );
  }
  callback(params.get('token'), params.get('expires_in'));
  navigate('/');
  return <></>;
};

B2CLoginDone.propTypes = {
  callback: PropTypes.func,
};

export default App;
