import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
} from '@mui/material';
import moment from 'moment';

// For adding any new desired functionality, please refer to https://mui.com/material-ui/react-table/
const EnhancedTable = ({
  cols,
  rows,
  pagination = false,
  sortableColumnIds = [],
  selectable = false,
  getSelectedRowIds = () => null,
  hideIdCol = false,
  reverseOrder = false,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(sortableColumnIds[0] || '');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState([]);

  // send the selected row IDs back
  useEffect(() => {
    getSelectedRowIds(selected);
  }, [selected]);

  // Add ids to rows if they're missing so the Select / SelectAll feature works
  if (selectable) {
    if (rows[0] && !('id' in rows[0])) {
      // id is missing from rows
      // eslint-disable-next-line no-param-reassign
      rows = rows.map((obj, index) => ({ ...obj, id: index })); // adds an Id property
      // eslint-disable-next-line no-param-reassign
      hideIdCol = true; // hide the bolted on Id column as there likely hasnt been given a column header for it.
    }
  }

  const descendingComparator = (a, b, orderedBy) => {
    let compare1 = a[orderedBy];
    let compare2 = b[orderedBy];

    // i.e. not a string or number, then presume a node has been passed (this is not fool proof </3 )
    if (typeof compare1 === 'object') {
      // presumes the child node's children is a string to be compared against if a label prop hasn't been passed. God help us
      compare1 = compare1.props?.label || compare1.props.children;
    }
    // see above comments
    if (typeof compare2 === 'object') {
      compare2 = compare2.props?.label || compare2.props.children;
    }

    // JS classes typeof Date as an object *sad face* So technically date compare falls into the above statements. So keep this explicite date compare under that to reasign it correctly.
    // This also assumes the ID for the date column used for comparision will always include 'date' in its string
    if (orderBy.toLowerCase().includes('date')) {
      compare1 = moment(compare1, 'DD-MM-YYYY').unix() || 0; // Or 0 if the value is a string such as N/A, Not Completed etc. so it's still sortable
      compare2 = moment(compare2, 'DD-MM-YYYY').unix() || 0;
    }

    // ignore case sensitivity where relevant
    if (typeof compare1 === 'string') {
      compare1 = compare1.toLowerCase();
    }
    if (typeof compare2 === 'string') {
      compare2 = compare2.toLowerCase();
    }

    if (compare2 < compare1) {
      return reverseOrder ? 1 : -1;
    }
    if (compare2 > compare1) {
      return reverseOrder ? -1 : 1;
    }
    return 0;
  };

  function getComparator(ordering, orderedBy) {
    return ordering === 'desc'
      ? (a, b) => descendingComparator(a, b, orderedBy)
      : (a, b) => -descendingComparator(a, b, orderedBy);
  }

  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      ordering,
      orderedBy,
      onRequestSort,
      numSelected,
      rowCount,
    } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {selectable && (
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                sx={{ color: 'white' }}
              />
            </TableCell>
          )}
          {cols.map(col => (
            <TableCell
              key={col.id}
              sortDirection={orderedBy === col.id ? ordering : false}
              sx={{ color: 'white' }}
            >
              {sortableColumnIds.includes(col.id) ? (
                <TableSortLabel
                  active={orderedBy === col.id}
                  direction={orderedBy === col.id ? ordering : 'asc'}
                  onClick={createSortHandler(col.id)}
                >
                  {col.label}
                </TableSortLabel>
              ) : (
                col.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    ordering: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderedBy: PropTypes.string.isRequired,
    onSelectAllClick: PropTypes.func,
    numSelected: PropTypes.number,
    rowCount: PropTypes.number,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() => {
    const sorted = rows.sort(getComparator(order, orderBy));
    if (pagination) {
      return sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    return sorted;
  }, [order, orderBy, page, rowsPerPage, rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = visibleRows.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  let rowKeys = [];
  if (visibleRows[0]) {
    rowKeys = Object.keys(visibleRows[0]);
  }

  if (hideIdCol) {
    rowKeys = rowKeys.filter(item => item !== 'id');
  }

  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 350,
        }}
        aria-labelledby="tableTitle"
      >
        <EnhancedTableHead
          ordering={order}
          orderedBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
        />
        <TableBody>
          {visibleRows.map((row, index) => {
            const isItemSelected = isSelected(row.id);
            return (
              <TableRow
                id={`table-row-${index}`}
                sx={{ fontFamily: 'sans-serif' }}
                hover
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
                {selectable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      onClick={event => handleClick(event, row.id)}
                      color="primary"
                      checked={isItemSelected}
                    />
                  </TableCell>
                )}
                {rowKeys.map((vr, i) => (
                  <TableCell
                    align={
                      typeof row[rowKeys[i]] === 'number' ? 'center' : 'left'
                    }
                  >
                    {row[rowKeys[i]]}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: 53 * emptyRows,
              }}
            >
              <TableCell colSpan={cols.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

EnhancedTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  cols: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.bool,
  sortableColumnIds: PropTypes.arrayOf(PropTypes.string),
  selectable: PropTypes.bool,
  getSelectedRowIds: PropTypes.func,
  hideIdCol: PropTypes.bool,
  reverseOrder: PropTypes.bool,
};

export default EnhancedTable;
