import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon';
import { CheckBox } from '../Input';

// COMPONENT NO LONGER IN USE - USE EnhancedTable INSTEAD
const Table = ({
  columns,
  rows,
  viewable,
  onRowClick,
  onRowSelect,
  action,
  hideHeader,
  widget,
  scroll,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelected = rowId => {
    selectedRows.includes(rowId)
      ? setSelectedRows(selectedRows.filter(item => item !== rowId))
      : setSelectedRows([...selectedRows, rowId]);
  };

  const toggleAll = () => {
    if (selectedRows.length === rows.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(rows.map(row => row.id));
    }
  };

  const rowClick = id => {
    if (viewable) {
      onRowClick(id);
    }
  };

  useEffect(() => {
    onRowSelect && onRowSelect(selectedRows);
  }, [selectedRows, onRowSelect]);

  return (
    <div
      className={`data-table ${widget && 'data-table--widget'} ${scroll &&
        'data-table--scroll'}`}
    >
      <table className="data-table__table">
        {!hideHeader && (
          <thead className="data-table__header">
            <tr className="data-table__header-row">
              {onRowSelect && (
                <th className="data-table__header-cell">
                  <CheckBox
                    id="toggle-all"
                    onChange={() => toggleAll()}
                    checked={selectedRows.length === rows.length}
                  />
                </th>
              )}
              {columns.map((header, index) => {
                if (typeof header === 'object') {
                  return (
                    <th
                      className="data-table__header-cell data-table__header-cell--centered"
                      key={`th-${index}`}
                    >
                      {header.text}
                    </th>
                  );
                }
                return (
                  <th className="data-table__header-cell" key={`th-${index}`}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
        )}
        <tbody className="data-table__body">
          {rows.map((row, index) => (
            <tr
              className={classNames('data-table__body-row', {
                'data-table__body-row--clickable': viewable,
              })}
              key={`tr-${index}`}
              onClick={() => rowClick(row.id)}
            >
              {onRowSelect && (
                <td className="data-table__body-cell">
                  <CheckBox
                    id={`check-${row.id}`}
                    onChange={() => rowSelected(row.id)}
                    checked={selectedRows.includes(row.id)}
                  />
                </td>
              )}
              {Object.values(row).map((value, valueIndex) => {
                if (typeof value === 'object') {
                  if (
                    value !== null &&
                    Object.prototype.hasOwnProperty.call(value, 'align')
                  ) {
                    if (value.align === 'center') {
                      return (
                        <td
                          className="data-table__body-cell data-table__body-cell--centered"
                          key={`td-${index}-${valueIndex}`}
                        >
                          {value.text}
                        </td>
                      );
                    }
                    return (
                      <td
                        className="data-table__body-cell data-table__body-cell--flex"
                        key={`td-${index}-${valueIndex}`}
                      >
                        {value.text}
                      </td>
                    );
                  }
                  return (
                    <td
                      className="data-table__body-cell"
                      key={`td-${index}-${valueIndex}`}
                    >
                      {value}
                    </td>
                  );
                }
                return (
                  <td
                    className="data-table__body-cell"
                    key={`td-${index}-${valueIndex}`}
                  >
                    {value}
                  </td>
                );
              })}
              {viewable && (
                <td className="data-table__body-cell data-table__body-cell--align-right">
                  <Icon name="ArrowRight" colour="primary" />
                </td>
              )}
            </tr>
          ))}
          {action && (
            <tr className="data-table__body-row">
              <td colSpan={100} style={{ padding: 0 }}>
                {action}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  viewable: PropTypes.bool,
  onRowClick: PropTypes.func,
  onRowSelect: PropTypes.func,
  action: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  hideHeader: PropTypes.bool,
  widget: PropTypes.bool,
  scroll: PropTypes.bool,
};

export default Table;
