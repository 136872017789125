import React from 'react';
import Toggle from 'react-toggle';
import PropTypes from 'prop-types';
import 'react-toggle/style.css';

const ToggleInput = ({ defaultChecked, label, onChange }) => (
  <label className="toggle">
    <Toggle
      className="toggle__control"
      defaultChecked={defaultChecked}
      icons={false}
      onChange={e => onChange(e)}
    />
    <span className="toggle__label">{label}</span>
  </label>
);

ToggleInput.propTypes = {
  defaultChecked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default ToggleInput;
