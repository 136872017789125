import gql from 'graphql-tag';

const GET_DOCUMENTS = gql`
  query GetDocuments($application: String!, $organisationId: Int) {
    gendocsList(application: $application, organisationId: $organisationId) {
      id
      title
      subType
      mimeType
      fileName
      metadata
      typeMetadata
      documentType
    }
  }
`;

const GET_DOCUMENT = gql`
  query GetDocument($documentId: Int!) {
    gendoc(documentId: $documentId) {
      id
      title
      subType
      downloadUrl
      author {
        email
      }
      allowedViewers
    }
  }
`;

const GET_APP_CHECK_DOCUMENTS = gql`
  query GetAppCheckScans {
    appCheckScans {
      id
      name
    }
  }
`;

const CREATE_DOCUMENT = gql`
  mutation CreateGendoc($newDocument: GenericDocumentCreateInput!) {
    gendocCreate(newDocument: $newDocument) {
      id
    }
  }
`;

const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($document: GenericDocumentUpdateInput!) {
    gendocUpdate(document: $document) {
      updated
    }
  }
`;

const REQUEST_DOCUMENT = gql`
  mutation RequestScan($scanRequest: RequestScanInput!) {
    requestScan(scanRequest: $scanRequest) {
      requested
    }
  }
`;

const GET_GENDOC_TYPES_LIST = gql`
  query gendocTypesList($application: String! $organisationId: Int!) {
    gendocTypesList(application: $application organisationId: $organisationId) {
      id
      typeName
    }
  }
`;

export {
  GET_DOCUMENTS,
  GET_DOCUMENT,
  GET_APP_CHECK_DOCUMENTS,
  CREATE_DOCUMENT,
  UPDATE_DOCUMENT,
  REQUEST_DOCUMENT,
  GET_GENDOC_TYPES_LIST
};
